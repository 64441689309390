import React, {Component} from 'react';
import Header from './components/Header'
import Activator from './components/Activator'
import {connect} from 'react-redux'
import {WIDTH, HEIGHT} from "./actions";

class App extends Component{
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {

        this.props.dispatch(WIDTH(window.innerWidth));
        this.props.dispatch(HEIGHT(window.innerHeight));


    }


    render() {

        return (
            <div style={divStyle}>
                <Header/>
                    <Activator screenWidth={this.props.width} screenHeight={this.props.height}/>
            </div>

        )
    };


}


function mapStateToProps(state) {
    return {
        count: state.counter.LH_State

    };
}

const divStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 0,
    padding: 0,
    overflowY: 'hidden',
    overflowX: 'hidden'
};

// in this object, keys become prop names,
// and values should be action creator functions.
// They get bound to `dispatch`.

export default connect(mapStateToProps)(App);
