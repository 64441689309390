export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const LH1x = "LH1";
export const LH2x = "LH2";
export const LH3x = "LH3";
export const LH4x = "LH4";
export const LH5x = "LH5";
export const LH6x = "LH6";
export const RH1x = "RH1";
export const RH2x = "RH2";
export const RH3x = "RH3";
export const ToolBar = "TOOLBAR";
export const ToolBarIcons_On = "TOOLBARICONS_ON";

export const WIN_WIDTH = "WIDTH";
export const WIN_HEIGHT = "HEIGHT";
export const zoomUp = "ZOOMUP";
export const zoomDown = "ZOOMDOWN";
export const zoomReset = "ZoomReset";
export const Label_X = "LABEL_X";
export const Label_Y = "LABEL_Y";
export const Label_Width = "LABEL_WIDTH";
export const Label_Height = "LABEL_HEIGHT";
export const Delete_object = 'Delete_Objects';
export const ActivatorOn = "ACTIVATOR_ON";
export const DataOn = "DATA_ON";


export function increment() {
    return { type: INCREMENT };
}

export const decrement = () => ({ type: DECREMENT });

export function LH1() {
    return { type: LH1x };
}

export function LH2() {
    return { type: LH2x };
}

export function LH3() {
    return { type: LH3x };
}

export function LH4() {
    return { type: LH4x };
}

export function LH5() {
    return { type: LH5x };

}
export function RH1() {
    return { type: RH1x };
}

export function RH2() {
    return { type: RH2x };
}

export function RH3() {
    return { type: RH3x };
}

export function TOOLBAR() {
    return { type: ToolBar };

}
export function TOOLBARICONS_ON() {
    return { type: ToolBarIcons_On };

}
export function ACTIVATOR_ON() {
    return { type: ActivatorOn };

}
export function DATA_ON() {
    return { type: DataOn };

}
export function Delete_Objects() {
    return { type: Delete_object };

}
export function ZOOMUP() {
    return { type: zoomUp };

}
export function ZOOMDOWN() {
    return { type: zoomDown };

}
export function ZOOMRESET() {
    return { type: zoomReset };

}
export function WIDTH(data) {
    return { type: WIN_WIDTH,
        payload: data
    };

}
export function HEIGHT(data) {
    return { type: WIN_HEIGHT,
        payload: data };

}
export function LABEL_X(data) {
    return { type: Label_X,
        payload: data };
}
export function LABEL_Y(data) {
    return { type: Label_Y,
        payload: data };
}
export function LABEL_WIDTH(data) {
    return { type: Label_Width,
        payload: data };
}
export function LABEL_HEIGHT(data) {
    return { type: Label_Height,
        payload: data };
}
