const initialState = {
    LH_State: "LH1",
    RH_State: "RH1",
    count: 0,
    Toolbar_Boolean: false,
    Window_Width: 0,
    Window_Height: 0,
    scale_factor: 1,
    Label_x: 0,
    Label_Y: 0,
    Label_Width: 0,
    Label_Height: 0,
    Delete_Objects: false,
    activator_Boolean: true,
    data_Boolean:true,
    toolbarIcons_On: false,



};

export default function reducer(state = initialState, action) {

    switch(action.type) {
        case 'LH1':
            return {
                ...state,
                LH_State: "LH1"
            };
        case 'LH2':
            return {
                ...state,
                LH_State: "LH2"
            };
        case 'LH3':
            return {
                ...state,
                LH_State: "LH3"
            };
        case 'LH4':
            return {
                ...state,
                LH_State: "LH4"
            };
        case 'LH5':
            return {
                ...state,
                LH_State: "LH5"
            };
        case 'RH1':
            return {
                ...state,
                RH_State: "RH1"
            };
        case 'RH2':
            return {
                ...state,
                RH_State: "RH2"
            };
        case 'RH3':
            return {
                ...state,
                RH_State: "RH3"
            };
        case 'DECREMENT':
            return {
                ...state,
                count: state.count - 1
            };
        case 'RESET':
            return {
                ...state,
                count: 0
            };
        case 'TOOLBAR':
            return {
                ...state,
                Toolbar_Boolean: !state.Toolbar_Boolean
            };
        case 'TOOLBARICONS_ON':
            return {
                ...state,
                toolbarIcons_On: !state.toolbarIcons_On
            };
        case 'ACTIVATOR_ON':
            return {
                ...state,
                activator_Boolean: !state.activator_Boolean
            };
        case 'DATA_ON':
            return {
                ...state,
                data_Boolean: !state.data_Boolean
            };
        case 'Delete_Objects':
            return {
                ...state,
                Delete_Objects: !state.Delete_Objects
            };
        case 'ZOOMUP':
            if (state.scale_factor > 0.5) {
                return {
                    ...state,
                    scale_factor: state.scale_factor + 0.5
                };
            }
            if (state.scale_factor <= 0.5) {
                return {
                    ...state,
                    scale_factor: state.scale_factor  * 2
                }
            }
            else {
                return {
                    ...state
                }
            }
        case 'ZOOMDOWN':
            if (state.scale_factor > 1) {
                return {
                    ...state,
                    scale_factor: state.scale_factor - 0.5
                }
            }
            else {
                return {
                    ...state
                }
            }

        case 'ZOOMRESET':
            return {
                ...state,
                scale_factor: 1
            };
        case 'WIDTH':
            return {
                ...state,
                Window_Width : action.payload
            };
        case 'HEIGHT':
            return {
                ...state,
                Window_Height : action.payload
            };
        case 'LABEL_X':
            return {
                ...state,
               Label_X : action.payload
            };
        case 'LABEL_Y':
            return {
                ...state,
                Label_Y : action.payload
            };

        case 'LABEL_WIDTH':
            return {
                ...state,
                Label_Width : action.payload
            };

        case 'LABEL_HEIGHT':
            return {
                ...state,
                Label_Height : action.payload
            };

        default:
            return state;
    }
}
