import React,{useState,useEffect} from 'react';
import {Modal} from "antd";
import './ViewCSV_Style.css'
import cancelIcon from "../assets/images/black_cancel.png";


const ViewCSV = (props) => {
    const [pageScroll,setPageScroll] = useState(20)

    const getHeaderHeight = ()=>{

        let f = 0;

        if (props.tableData){
            if (props.tableData[0]) {
                for (let i = 0; i < props.tableData[0].length; i++) {
                    // if (props.tableData[0][i].length > 0) {
                    if (props.tableData[0][i].length < 8) {
                        f = f + 10
                    } else {
                        f = f + props.tableData[0][i].length * 1.5
                    }
                    // }

                }
            }
        }
        return f

    }
    const fetchNextPage = ()=>{
        if (props.tableData.length > 0 && pageScroll <= props.tableData.length){
            setPageScroll(pageScroll+5)
        }
    }
    const checkEmpty = (arr)=>{
        for (let i=1;i < arr.length;i++){
            if (arr[i].length > 0){
                return true
            }
        }
        return false

    }
    return (

        <Modal id={'view_csv'}

               visible={props.status} width={'80vw'}
               changeStatus={props.changeStatus}


               closable={false}
               destroyOnClose={true}
               style={{
                   height: '80vh', padding: 0, borderRadius: 0, top: '6.5vw',left:'10vw',margin:0,
                   display: 'flex', flexDirection: 'column',position:'absolute',zIndex:20,backgroundColor:'white',


               }} footer={null}
               bodyStyle={ant_body}>
            <div style={{display:'flex',flexDirection:'column',justifyContent:'space-around',
                }}>
                <img alt={'cancelIcon'} src={cancelIcon} style={{width:'0.75vw',height:'0.75vw',cursor:'pointer',
                marginLeft:'77vw',marginTop:'0.625vw'}} onClick={()=>{
                    props.changeStatus()
                }}/>
                <div   style={{display:'flex',flexDirection:'column',
                    marginTop:'1.2vw',justifyContent:'space-around',overflowX:'hidden',}}>
                    <div id={'colscroll'} style={{display:'flex',overflowX:'hidden',justifyContent:'space-around',flexDirection:'column',}}>
                    {
                        props.tableData ?

                                props.tableData.map((item,index)=>{
                                    if (index === 0){
                                        return(
                                            <div  style={{
                                                // ...table_div,
                                                display:'flex',flexDirection:'row',
                                                overflowX:'scroll',

                                                paddingBottom:'0.5vw',
                                                paddingLeft:'1vw',paddingTop:'0.3vw',paddingRight:'1vw',
                                                marginTop:'0.625vw',backgroundColor:'#FACE4E',
                                                width: getHeaderHeight()+'vw'

                                            }}>
                                                {
                                                    item.map((c,cIndex)=>{
                                                        return(
                                                            <div  style={{display:'flex',marginTop:'0.5vw',justifyContent:'center',
                                                                width: c.length === 0 ? '10vw':c.length < 8 ? '10vw':c.length*1.5+'vw',backgroundColor:'#FACE4E',
                                                            }}
                                                            >
                                                                        <div style={{textAlign:'center',
                                                                            alignSelf:'center',fontFamily: 'verdana',color:'#545454',
                                                                            fontSize:'1vw'}}>{c}</div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        )}

                                }) : null

                    }
                    </div>
                    <div style={{display:'flex',height:'32vw',overflowY:'scroll',width:'100%',overflowX:'scroll'}}
                         onScroll={(e)=>{
                             fetchNextPage()
                        let fg =  document.getElementById('colscroll')
                        fg.scrollLeft = e.currentTarget.scrollLeft
                    }}
                    >
                    <div style={{display:'flex',flexDirection:'column',height:'30vw',position:'relative',
                       width:getHeaderHeight()+'vw'}}>

                    {
                    props.tableData ? props.tableData.map((item,index)=> {
                                if (index > 0 && index < pageScroll && checkEmpty(item)){
                            return (
                            <div style={index % 2 === 0 ? {
                                    ...table_div, backgroundColor: '#EEEEEE',
                                    width: getHeaderHeight()+'vw'

                                }
                                :
                                {
                                    ...table_div,
                                    width: getHeaderHeight()+'vw'

                                }}>
                                {
                                    item.map((c,cIndex) => {

                                        return (
                                            <div style={{
                                                display: 'flex',
                                                marginTop: '0.5vw',
                                                justifyContent: 'center',
                                                width: props.tableData[0][cIndex].length === 0 ? '10vw':props.tableData[0][cIndex].length < 8 ? '10vw':props.tableData[0][cIndex].length*1.5+'vw',
                                            }}>
                                                <div  style={index === 0 ? {
                                                    textAlign: 'center',
                                                    alignSelf: 'center',
                                                    fontFamily: 'verdana',
                                                    color: '#545454',
                                                    fontSize: '1vw',
                                                    overflowWrap: 'break-word',
                                                    width:'90%'
                                                } : {
                                                    textAlign: 'center',
                                                    alignSelf: 'center', fontFamily: 'verdana',
                                                    fontSize: '1vw',
                                                    overflowWrap: 'break-word',
                                                    width:'90%'
                                                }}>{c}</div>
                                            </div>


                                        )
                                    })
                                }
                            </div>
                        )
                    }
                        }
                        )
                        : null
                }
                    </div>
                </div>
                </div>
            </div>

        </Modal>
    )}
const ant_body= {
    width: '100%', height: '39.5vw',
    borderRadius: 0, border: '0.2vw solid #FACE4E', maxWidth:'79.8vw',
    minWidth:'1vw',padding:0,margin:0
}
const table_div = {
    display:'flex',flexDirection:'row',width:'80vw',paddingBottom:'0.5vw',
    paddingLeft:'0.5vw',paddingTop:'0.3vw',paddingRight:'1vw',marginTop:'0.4vw'
}

export default ViewCSV
