import React,{useState,useEffect} from 'react';
import {Modal} from "antd";
import cancelIcon from '../assets/images/black_cancel.png'
import { css } from "@emotion/core";
import Select from 'react-select'
import language_Options from "../assets/translations/translator";


const options = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Español' },
    { value: 'French', label: 'Français' },
    { value: 'German', label: 'Deutsche' },
    { value: 'Chinese', label: '中文' },
    { value: 'Portuguese', label: 'Português' },
    { value: 'Russian', label: 'русский' },
    { value: 'Italian', label: 'Italiano' },
    { value: 'Vietnamese', label: 'Tiếng Việt' },


]

const Settings = (props) => {

    const [selectedLanguage,setSelectedLanguage] = useState('English')
    const [selectLanguage,setSelectLanguage] = useState(0)



    useEffect(() => {

        for (let i =0; i < options.length;i++){
            if (props.labelData === options[i].value){
                if (selectLanguage !== i){
                    setSelectLanguage(i)
                }
            }
        }



    });


    return (
        <Modal

            visible={props.status} width={'50vw'}


            closable={false}

            destroyOnClose={true}
            style={{
                height: '10vw', padding: 0, borderRadius: 0, top: '6.5vw',left:'25vw',
                display: 'flex', flexDirection: 'column',position:'absolute',zIndex:4,


            }} footer={null}
            bodyStyle={ant_body}>

            <div style={root_div}>

                <div style={{display:'flex',flexDirection:'row',width:'50vw',backgroundColor: '#FACE4E'}}>

                    <div style={{...question_txt_style}}>{props.langData.settings}</div>
                    <img alt={'cancelIcon'} src={cancelIcon} style={cancelIconStyle} onClick={()=>{
                        setSelectedLanguage(props.labelData)
                        props.changeStatus()
                    }}/>
                </div>
                <div  style={{...col2_div}}>

                    <div id={'column1'} style={{display:'flex',flexDirection:'column',height:'auto',marginTop:'0.3vw'}}>

                                    <div style={txt_div}>
                                        <div style={{fontFamily: 'verdana', fontSize:'1.2vw' }}>{language_Options[selectedLanguage].popup.lang}</div>

                                    </div>

                    </div>
                    <div id={'column2'} style={{...col2,marginLeft:'3vw',width:'12vw',}}>

                        <div  style={{width:'12vw',marginTop:'0.75vw'}} >
                            <Select maxMenuHeight={200} styles={customStyles}     defaultValue={options[selectLanguage]}
                                         options={options} onChange={(d)=>{
                                             setSelectedLanguage(d.value)
                            }}/>

                        </div>


                    </div>
                    <div onClick={()=>{
                        props.setLanguage(selectedLanguage)
                        props.changeStatus()
                    }}
                        style={{...popup_btn_style,marginRight:'1vw',marginLeft:'1.5vw',marginTop: '0.7vw',
                            fontFamily: 'verdana',fontSize:'1vw'}}>{language_Options[selectedLanguage].popup.setTrans}</div>
                </div>

            </div>


        </Modal>
    )
}

const question_txt_style = {
    width: '46vw', height: '3vw',
    fontFamily: 'verdana',fontSize:'1.2vw',textAlign:'center',
    alignSelf:'center',justifyContent:'center',
    color: '#000000', display: 'flex', alignItems: 'center',
}
const popup_btn_style = {
    backgroundColor:'#56CFE1',color:'white',width:'20vw',height:'2.5vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    fontFamily: 'verdana',fontSize:'1.2vw',borderRadius:'1.5vw'
}
const cancelIconStyle = {
    width:'1vw',height:'1vw',marginTop:'1vw',cursor:'pointer'
}
const input_div = {
   overflow:'hidden', height:'2vw',minHeight:'2vw',borderRadius:'1.5vw',
    paddingLeft:'0.4vw',
    width:'23vw',maxWidth:'30vw',minWidth:'1vw',outline:'none',border:'0.06vw solid black',marginTop:'0.75vw'
}
const txt_div = {
    display:'flex',marginTop:'0.75vw',marginLeft:'2vw',justifyContent:'left',
    height:'1.82vw',paddingTop:'0.3vw',width:'8vw'
}
const root_div = {
    height:'100%',display:'flex',flexDirection:'column',
    borderRadius: 0,backgroundColor:'white',
    width:'50vw'
}
const ant_body= {
    width: '100%', height: '37vw',resize: 'horizontal',overflow: 'auto',
    borderRadius: 0, border: '0.2vw solid #FACE4E', maxWidth:'50vw',
    minWidth:'1vw'
}
const col2box_Style = {
    width:'6vw',height: '2vw',backgroundColor:'#FFFFFF',marginLeft:'1vw',
    display:'flex',flexDirection:'row',border:'0.06vw solid black',borderRadius:'1.5vw'
}
const col2_div = {
    height:'23.75vw',width:'100%',overflow:'auto',display:'flex',flexDirection:'row',paddingTop: '1.25vw'
}
const col2 = {
    display:'flex',flexDirection:'column',width:'auto',height:'auto'
}
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 0,
        border:'0.06vw solid black',
        borderRadius:'1.25vw',
        boxShadow: 'none',
        '&:hover': {
            border: '0.06vw solid black !important'
        }
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
        width:'2.26ex',
        strokeWidth:1,

    }),
    clearIndicator: base => ({
        ...base,
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        fontSize:'1.2vw',
        fontFamily:'verdana',
        paddingLeft:'0.5vw',
        top:'40%',
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        height:'100%',
        border:'none'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
        height:'100%',
        paddingLeft:'0.5vw'
    }),
    indicatorsContainer: base => ({
        ...base,
        padding: 0,
        height:'2.66vw',
    }),
    menuList: base => ({
        ...base,
        fontSize:'1.2vw',
        fontFamily:'verdana',
    }),
    // option: (base, state) => ({
    //     ...base,
    //     overflowY:'scroll'
    // })

};
const override = css`
  display: block;
  margin: 25vw 18vw 20vw 20vw;
  border-color: red;
  position:absolute;
`;

export default Settings;
