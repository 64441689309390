import React from 'react';
import {Modal} from "antd";

const ImageErrorPopup = (props) => {
    return (
        <Modal
            visible={props.status} width={'22vw'}
            closable={false}

            destroyOnClose={true}
            style={{
                height: '10vw', padding: 0, borderRadius: 0, top: '20vw',left:'40vw',
                display: 'flex', flexDirection: 'column',position:'absolute',zIndex:4
            }} footer={null}
            bodyStyle={{
                width: '100%', height: '10vw',
                borderRadius: 0, border: '0.2vw solid #FACE4E',
            }}>
            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',
                borderRadius: 0,backgroundColor:'white'}}>

                <div style={{
                    width: '22.2vw', height: '3vw', backgroundColor: '#FACE4E'
                    , fontFamily: 'verdana',fontSize:'1.2vw',textAlign:'center',
                    alignSelf:'center',justifyContent:'center',marginTop:'-0.2vw',
                    color: '#000000', display: 'flex', alignItems: 'center',
                }}>
                    {props.langData.imageError}
                </div>

                <div style={{
                    color: 'black',
                    backgroundColor: 'white',
                    paddingTop: '1vw',textAlign:'center',
                    height:'3vw',
                    fontFamily: 'verdana',fontSize:'1.2vw',
                    paddingLeft:'1vw',paddingRight:'1vw',
                }}>{props.text}
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    paddingTop:'1.5vw',
                    height:'4vw',
                    width: '22vw',
                    justifyContent:'center'
                }}>

                    <div style={{...popup_btn_style}}
                         onClick={props.changeStatus}>{props.langData.ok}
                    </div>

                </div>
            </div>
        </Modal>
    )
}
const popup_btn_style = {
    backgroundColor:'#56CFE1',color:'white',width:'8.5vw',height:'2vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    fontFamily: 'verdana',fontSize:'1.2vw',borderRadius:'1.5vw'
}
export default ImageErrorPopup;
