import React, {Component} from 'react'
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import ColorPicker from 'rc-color-picker';
import './colorPicker.css';
import './toolTip_Style.css'

const para_Line = require('../../assets/Tab_Images/Text/btn_Text_Line_Space_Enabled.png');
const para_Line_off = require('../../assets/Tab_Images/Text/btn_Text_Line_Space_Disabled.png');

const btn_X_e = require('../../assets/Tab_Images/Text/lbl_X-dim-Enabled.png');
const btn_X_d = require('../../assets/Tab_Images/Text/lbl_X-dim-Disabled.png');

const btn_Y_e = require('../../assets/Tab_Images/Text/lbl_Y-dim-Enabled.png');
const btn_Y_d = require('../../assets/Tab_Images/Text/lbl_Y-dim-Disabled.png');

const left_On = require('../../assets/Tab_Images/Text/btn_Move_Left_Enable.png');
const left_Off = require('../../assets/Tab_Images/Text/btn_Move_Left_Disabled.png');

const right_On = require('../../assets/Tab_Images/Text/btn_Move_Right_Enabled.png');
const right_Off = require('../../assets/Tab_Images/Text/btn_Move_Right_Disabled.png');

const up_On = require('../../assets/Tab_Images/Text/btn_Move_Up_Enabled.png');
const up_Off = require('../../assets/Tab_Images/Text/btn_Move_Up_Disabled.png');

const down_On = require('../../assets/Tab_Images/Text/btn_Move_Down_Enabled.png');
const down_Off = require('../../assets/Tab_Images/Text/btn_Move_Down_Disabled.png');

const rotateLeft_e = require('../../assets/Tab_Images/Text/btn_Rotate_Left_Enabled.png');
const rotateLeft_d = require('../../assets/Tab_Images/Text/btn_Rotate_Left_Disabled.png');

const rotateRight_e = require('../../assets/Tab_Images/Text/btn_Rotate_Right_Enabled.png');
const rotateRight_d = require('../../assets/Tab_Images/Text/btn_Rotate_Right_Disabled.png');

const forward_e = require('../../assets/Tab_Images/Text/btn_Front_Enabled.png');
const forward_d = require('../../assets/Tab_Images/Text/btn_Front_Disabled.png');

const backward_e = require('../../assets/Tab_Images/Text/btn_Back_Enabled.png');
const backward_d = require('../../assets/Tab_Images/Text/btn_Back_Disabled.png');

const tick = require('../../assets/Tab_Images/Text/tick.png');

const arrow_Up = require('../../assets/Tab_Images/Text/arrow_up.png')
const arrow_Down = require('../../assets/Tab_Images/Text/arrow_down.png')
const text_Input = require('../../assets/componentImages/buttonTextInput.jpg');

const left_AlignOff = require('../../assets/Tab_Images/Text/btn_Text_Left_Disabled.png');
const left_AlignOn = require('../../assets/Tab_Images/Text/btn_Text_Left_Enabled.png');

const center_AlignOff = require('../../assets/Tab_Images/Text/btn_Text_Centre_Disabled.png');
const center_AlignOn = require('../../assets/Tab_Images/Text/btn_Text_Centre_Enabled.png');

const right_AlignOff = require('../../assets/Tab_Images/Text/btn_Text_Right_Disabled.png');
const right_AlignOn = require('../../assets/Tab_Images/Text/btn_Text_Right_Enabled.png');

const transparent_e = require('../../assets/Tab_Images/Text/btn_TextTransparent_Enabled-8.png')
const transparent_d = require('../../assets/Tab_Images/Text/btn_TextTransparent_Disabled-8.png')

const options = [
    { label: 'CODE128',value: 1 },
    // { label: 'CODE128B',value: 2 },
    // { label: 'CODE128C',value: 3 },
    { label: 'CODE39',value: 2 },
    { label: 'EAN8',value: 3 },
    { label: 'EAN13',value: 4 },
    { label: 'UPC(A)',value: 5 },
    { label: 'UPC(E)',value: 6 },
    // { label: 'MSI10',value: 9 },
    // { label: 'MSI11',value: 10},
    { label: 'QR CODE',value: 7 },

];
const options_DPI = [
    { label: '200 dpi',value: 1},
    { label: '300 dpi',value: 2 },
    { label: '600 dpi',value: 3 },
    { label: '1200 dpi',value: 4 },
];

class BarcodeLayer extends Component {
    constructor(props) {
        super(props);

        // This line is important!
    }
    state = {
        digit: false,
        fixedData: false,
        question: false,
        date: false,
        increment: false,
        lock: false,
        no_print: false,
        bold: true,
        italics: false,
        strike:false,
        underline: false,
        reverse: false,
        leftAlign: false,
        centerAlign: false,
        rightAlign: false,
        multi: false,
        clock: false,
        anti_clock: false,
        forward: false,
        backward: false,

    };
    changeHandler = (colors) => {
    };

    render() {
        let selectedBarcodeBoolean = false;
        if (this.props.barcodeValueStatus) {
            selectedBarcodeBoolean = true;
        }

        return(

            <div style={{ backgroundColor: '#EEEEEE', padding: 0,
                width : "21vw",zIndex:3,minHeight:this.props.heightLayer}}>

                <ReactTooltip place="bottom" type="dark" effect="float"/>


                <div  data-tip={this.props.langData.name}  data-place={'right'} data-offset="{'left': 0,'right':0}"
                      style={{marginLeft:'2.6vw', marginRight:'2.5vw', width:"15.5vw",
                    marginTop: '1.7vw',height:'2.66vw'}}>
                <input style={{backgroundColor:'white',width:'100%',height:'100%',
                    textAlign:'center',justifyContent:'center', alignItems:'center',fontFamily: 'verdana',
                    fontSize:'1vw',border:'none'}}
                       disabled={!selectedBarcodeBoolean}  type="text" align={'center'} value={this.props.barcodepinTextStatus}
                       onChange={this.props.barcodechangePinText}
                       />
                </div>
                <div style={container_Style}>
                    <div style={row1}>
                        <div  data-tip={this.props.langData.symbol} data-place={'right'} data-offset="{'left': 0,'right':0}" style={{width:'16vw'}}>
                            {/*{*/}
                            {/*    this.props.clickedBarcode === -1 ?*/}
                            {/*        <Select isDisabled styles={customStyles} onChange={value => this.props.barcodeTypeChange(value)} value={{ label: this.props.barcodeTypeStatus, value:this.props.barcodeTypeStatus_Value}}*/}
                            {/*                defaultValue={{ label: this.props.barcodeTypeStatus, value: this.props.barcodeTypeStatus_Value}} options={options}   /> :*/}
                            {/*        <Select styles={customStyles} onChange={value => this.props.barcodeTypeChange(value)} value={{ label: this.props.barcodeTypeStatus, value:this.props.barcodeTypeStatus_Value}}*/}
                            {/*                defaultValue={{ label: this.props.barcodeTypeStatus, value: this.props.barcodeTypeStatus_Value}} options={options}   />*/}

                            {/*}*/}
                            <Select styles={customStyles} onChange={value => this.props.barcodeTypeChange(value)} value={{ label: this.props.barcodeTypeStatus, value:this.props.barcodeTypeStatus_Value}}
                                    defaultValue={{ label: this.props.barcodeTypeStatus, value: this.props.barcodeTypeStatus_Value}} options={options}   />
                        </div>
                    </div>

                    <div style={{...row2_L2,marginTop:'0.7vw'}}>
                        <div data-tip={this.props.langData.dpi} data-place={'right'} data-offset="{'left': 0,'right':0}" style={{width: '7.9vw'}}>
                            {
                                this.props.qrcodeStatus || this.props.clickedBarcode === -1 ?
                                    <Select styles={customStyles}  isDisabled  onChange={value => this.props.barcodeDPIChange(value)} value={{ label: this.props.barcodeDPIStatus, value: this.props.barcodeDPIStatus_Value}}
                                             defaultValue={{ label: this.props.barcodeDPIStatus, value:this.props.barcodeDPIStatus_Value }} options={options_DPI}   /> :
                                    <Select styles={customStyles}   onChange={value => this.props.barcodeDPIChange(value)} value={{ label: this.props.barcodeDPIStatus, value: this.props.barcodeDPIStatus_Value}}
                                             defaultValue={{ label: this.props.barcodeDPIStatus, value:this.props.barcodeDPIStatus_Value }} options={options_DPI}   />
                            }

                        </div>
                        <div data-tip={this.props.langData.size} data-place={'right'} data-offset="{'left': 0,'right':0}"
                             style={{width: '7.9vw',marginLeft:'0.2vw'}}>
                            {
                                this.props.qrcodeStatus  || this.props.clickedBarcode === -1 ?
                                    <Select isDisabled styles={customStyles} value={{
                                        label: this.props.barcodeDPI_XDIM,
                                        value: this.props.barcodeDPI_XDIM_Value
                                    }} onChange={value => this.props.barcodeDPI_XDIM_Change(value)}
                                            defaultValue={{
                                                label: this.props.barcodeDPI_XDIM,
                                                value: this.props.barcodeDPI_XDIM_Value
                                            }} options={this.props.barcodedpi_Options}/> :
                                    <Select styles={customStyles} value={{
                                        label: this.props.barcodeDPI_XDIM,
                                        value: this.props.barcodeDPI_XDIM_Value
                                    }} onChange={value => this.props.barcodeDPI_XDIM_Change(value)}
                                            defaultValue={{
                                                label: this.props.barcodeDPI_XDIM,
                                                value: this.props.barcodeDPI_XDIM_Value
                                            }} options={this.props.barcodedpi_Options}/>
                            }
                    </div>


                    </div>
                    <div style={{...row2_L2,marginTop:'0.6vw',display:'flex',alignItems:'center'}}>
                        <div style={btn_Style}>
                            <img style={img_row1_C1} alt='multi'  src={selectedBarcodeBoolean ? para_Line: para_Line_off} />
                        </div>
                        {/*<input style={input_style_para} onChange={this.props.changebarcodeHeight} type="text" align={'center'} value={this.props.barcodeHeightStatus}  defaultValue={this.props.barcodeHeightStatus} />*/}

                        <div data-tip={this.props.langData.height} data-place={'right'} data-offset="{'left': 0,'right':0}"  style={{width:'5.2vw',height:'2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                            <input onChange={this.props.changebarcodeHeight} type="text" align={'center'} value={this.props.barcodeHeightStatus}   style={{width:'4vw',outline:'none',border:'none',
                                height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                            <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                                <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={() =>this.props.changebarcodeHeightArrow((Number(this.props.barcodeHeightStatus)+0.1))}/>

                                <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={() =>this.props.changebarcodeHeightArrow((Number(this.props.barcodeHeightStatus)-0.1))}/>


                            </div>
                        </div>



                        <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.invert}
                             style={{...btn_Style,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.5vw',height:'2.5vw',opacity:'0.1',
                            display:'flex',justifyContent:'center',alignItems:'center',marginLeft:'0.2vw'}} onClick={this.props.onBarcodeNoPrint}>
                            {
                                // this.props.barcodeNoPrintStatus ?
                                //     <img style={{...img_row1_C1,width:'70%'}} alt='new button'  src={tick}  />:
                                //     null

                            }
                        </div>
                        <div style={{...text_Style,marginLeft:'0.7vw'}}>Inverse</div>

                    </div>
                    <div style={{...row2_L2}}>
                        <div style={btn_Style} data-tip={this.props.langData.left} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='left'  src={left_AlignOn}  />
                        </div>
                        <div style={btn_Style} data-tip={this.props.langData.center} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='center'  src={center_AlignOff} />
                        </div>
                        <div style={btn_Style} data-tip={this.props.langData.right} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='right'  src={right_AlignOff} />
                        </div>
                        <div data-tip={this.props.langData.bcdColor} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <ColorPicker  color={this.props.lineColor}  onChange={this.props.onbarcodeLineChange} placement="bottomRight" />
                        </div>
                        <div style={{marginLeft: '0.1vw'}} data-tip={this.props.langData.bColor} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <ColorPicker  color={this.props.backgroundColor === 'transparent' ? '#FFFFFF':this.props.backgroundColor}  onChange={this.props.onbarcodeBackgroundChange} placement="bottomRight" />
                        </div>
                        <div style={{...btn_Style,backgroundColor:'#FFFFFF'}} data-tip={this.props.langData.transparent} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={{...img_row1_C1,width:'100%',height:'100%'}} alt='right'   src={this.props.statusTransparent ? transparent_e :transparent_d}
                                 onClick={this.props.changeTransparent} />
                        </div>

                    </div>

                 <div style={row2_L2}>
                        <div style={btn_Style}>
                            <img style={img_row1_C1} alt='x'  src={selectedBarcodeBoolean ? btn_X_e: btn_X_d} />
                        </div>
                     <div data-tip={this.props.langData.pLeft} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <input style={input_style} type="text" align={'center'}
                               onChange={this.props.barcodeXPosChange}  value={this.props.barcodeXposStatus}/>
                     </div>

                     <div style={btn_Style}>
                         <img style={img_row1_C1} alt='y'  src={selectedBarcodeBoolean ? btn_Y_e: btn_Y_d} />

                     </div>
                     <div data-tip={this.props.langData.pTop} data-place={'right'} data-offset="{'left': 0,'right':0}">
                     <input style={input_style} type="text" align={'center'}
                            onChange={this.props.barcodeYPosChange} value={this.props.barcodeYposStatus} />
                     </div>
                    </div>

                    <div style={row2_L2}>

                        <div onMouseUp={this.props.onBarcodeRightMouseUp} onMouseDown={this.props.onBarcodeRightMouseDown} style={btn_Style} data-tip={this.props.langData.mR} data-place={'right'} data-offset="{'left': 0,'right':0}" >
                            <img style={img_row1_C1} alt='right'   src={selectedBarcodeBoolean ? right_On: right_Off} />
                        </div>
                        <div onMouseUp={this.props.onBarcodeUpMouseUp} onMouseDown={this.props.onBarcodeUpMouseDown} style={btn_Style} data-tip={this.props.langData.mU} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='up'   src={selectedBarcodeBoolean ? up_On: up_Off} />
                        </div>
                        <div onMouseUp={this.props.onBarcodeDownMouseUp} onMouseDown={this.props.onBarcodeDownMouseDown} style={btn_Style} data-tip={this.props.langData.mD} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='down'   src={selectedBarcodeBoolean ? down_On: down_Off} />
                        </div>
                        <div onMouseUp={this.props.onBarcodeLeftMouseUp} onMouseDown={this.props.onBarcodeLeftMouseDown} style={btn_Style} data-tip={this.props.langData.mL} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='left'   src={selectedBarcodeBoolean ? left_On: left_Off} />
                        </div>



                    </div>
                    <div style={row2_L2}>
                        <div onClick={this.props.clockAntiTransform} style={btn_Style} data-tip={this.props.langData.cclock} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='clock'  src={selectedBarcodeBoolean ? rotateLeft_e: rotateLeft_d} />
                        </div>
                        <div onClick={this.props.clockTransform} style={btn_Style} data-tip={this.props.langData.clock} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='anticlock'  src={selectedBarcodeBoolean ? rotateRight_e: rotateRight_d} />

                        </div>
                        <div data-tip={this.props.langData.degree} data-place={'right'} data-offset="{'left': 0,'right':0}" style={{width:'5.32vw',height: '2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                            <input onChange={this.props.changeRotation}  disabled={!selectedBarcodeBoolean}  value={selectedBarcodeBoolean ? this.props.transformRotateValue: ''}   style={{width:'4vw',outline:'none',border:'none',marginLeft:'0.1vw',height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                            <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                                <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={selectedBarcodeBoolean ? ()=>this.props.numberChangeRotation(Math.round(Number(this.props.transformRotateValue))+1): null} />

                                <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={selectedBarcodeBoolean ? ()=>this.props.numberChangeRotation(Math.round(Number(this.props.transformRotateValue))-1):null} />

                            </div>
                        </div>
                        <div onClick={selectedBarcodeBoolean ? this.props.bringForward: null} style={btn_Style} data-tip={this.props.langData.forward} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='forward'  src={selectedBarcodeBoolean ? forward_e: forward_d} />

                        </div>
                        <div onClick={selectedBarcodeBoolean ? this.props.bringBackward: null}  style={btn_Style} data-tip={this.props.langData.backward} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='backward'  src={selectedBarcodeBoolean ? backward_e: backward_d} />
                        </div>

                    </div>

                    <div style={{...row2_L2}}>
                        <div style={{display:'flex',flexDirection:'row',flex:1,alignItems:'center'}}>
                            <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.lock}
                                 style={{...btn_Style,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.5vw',height:'2.5vw',

                                display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:'0.2vw'}} onClick={this.props.onBarcodeLock}>
                                {
                                    this.props.barcodeLockedStatus ?
                                        <img style={{...img_row1_C1,width:'70%'}} alt='new button'  src={tick}  />:
                                        null

                                }
                            </div>
                            <div style={{...text_Style,marginLeft:'0.4vw'}}>{this.props.langData.lockT}</div>
                        </div>

                        <div style={{display:'flex',flexDirection:'row',flex:1,alignItems:'center'}}>
                            <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.noPrint}
                                 style={{...btn_Style,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.5vw',height:'2.5vw',
                                display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:'0.2vw'}} onClick={this.props.onBarcodeNoPrint}>
                                {
                                    this.props.barcodeNoPrintStatus ?
                                        <img style={{...img_row1_C1,width:'70%'}} alt='new button'  src={tick}  />:
                                        null

                                }
                            </div>
                            <div style={{...text_Style,marginLeft:'0.4vw'}}>{this.props.langData.noPrintT}</div>
                        </div>
                    </div>

                <div style={{...row5,cursor:'pointer'}} onClick={this.props.addtoBarocdeArray}>
                    <div style={{color:'white',fontSize: '1vw',fontFamily: 'verdana',}}>{this.props.langData.newBarcode}</div>
                </div>

                </div>


            </div>

        )

    }
}
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 0,
        border:'none',
        borderRadius:0,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
        width:'2.26ex',
        strokeWidth:1,

    }),
    clearIndicator: base => ({
        ...base,
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        fontSize:'0.8vw',
        fontFamily:'verdana',
        paddingLeft:'0.5vw'
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        height:'2.66vw',
        border:'none'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
        height:'2.66vw'
    }),
    indicatorsContainer: base => ({
        ...base,
        padding: 0,
        height:'2.66vw',
    }),
    menuList: base => ({
        ...base,
        fontSize:'0.8vw',
        fontFamily:'verdana',
    }),

};
const container_Style={
    border: 'none',marginLeft:'2.5vw', marginRight:'2.5vw',
    width:'16vw',

};

const row1 = {
    marginLeft:'0.1vw', height: '2.66vw',marginRight:'0.1vw', marginTop:'0.8vw',border:'none',
    display:'flex',flexDirection:'row'
};


const row2 = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: '2vw',marginTop:'2%',marginRight:'0.1vw'

};

const row5 = {
    flex:1, flexDirection:'row',display:'flex',width:'16vw',justifyContent:'center',alignItems:'center',backgroundColor: '#F58C32',
    height: '2.5vw', marginTop: '0.6vw',border: "0.13vw solid white",

};




const btn_Style={
    width: '2.66vw',height:'2.66vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc'
};


const input_style = {
    width:'5.2vw',height: '2.66vw',textAlign:'center', display:'flex', margin: 0, padding: 0, borderWidth: 0,
    fontFamily: 'verdana',fontSize:'0.8vw'
};
const input_style_para = {
    width:'5.2vw',height: '2.66vw',textAlign:'center', display:'flex', margin: 0, padding: 0, borderWidth: 0,
    fontFamily: 'verdana',fontSize:'0.8vw'
}

const input_style_Text = {
    width:'14vw',height: '1.6vw',textAlign:'center', display:'flex', margin: 0, padding: 0, borderWidth: 0,
    fontFamily: 'verdana',fontSize:'0.8vw'
};
const img_row1_C1 = {
    width:'90%',height:'auto', cursor:'pointer'

};
const text_Style={
    marginLeft:'0.2vw',fontSize:'0.8vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
};
const row2_L2 = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: '2.66vw',marginTop:'0.6vw',marginRight:'0.1vw'

};

export default connect()(BarcodeLayer);
