import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk';
import App from './App'
import reducer from './reducer/index';
import './assets/fonts/caviar_dreams/CaviarDreams.ttf';
import './assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf';
import './assets/fonts/caviar_dreams/CaviarDreams_BoldItalic.ttf';
import './assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf';
import './assets/fonts/Actor/Actor-Regular.ttf';
import './assets/fonts/Adamina/Adamina-Regular.ttf';
import './assets/fonts/Akronim/Akronim-Regular.ttf';
import './assets/fonts/Aldrich/Aldrich-Regular.ttf';
import './assets/fonts/Allura/Allura-Regular.ttf';
import './assets/fonts/Almendra/Almendra-Bold.ttf';
import './assets/fonts/Almendra/Almendra-BoldItalic.ttf';
import './assets/fonts/Almendra/Almendra-Italic.ttf';
import './assets/fonts/Almendra/Almendra-Regular.ttf';
import './assets/fonts/Amiri/Amiri-Bold.ttf';
import './assets/fonts/Amiri/Amiri-BoldSlanted.ttf';
import './assets/fonts/Amiri/Amiri-Regular.ttf';
import './assets/fonts/Amiri/Amiri-Slanted.ttf';
import './assets/fonts/Andika/Andika-R.ttf';
import './assets/fonts/Arial/arial.ttf';
import './assets/fonts/Arial/arialb.ttf';
import './assets/fonts/Arial/arialbi.ttf';
import './assets/fonts/Arial/ariali.ttf';
import './assets/fonts/Arimo/Arimo-Bold.ttf';
import './assets/fonts/Arimo/Arimo-BoldItalic.ttf';
import './assets/fonts/Arimo/Arimo-Italic.ttf';
import './assets/fonts/Arimo/Arimo-Regular.ttf';
import './assets/fonts/Arizonia/Arizonia-Regular.ttf';
import './assets/fonts/Baloo/Baloo-Regular.ttf';
import './assets/fonts/Basic/Basic-Regular.ttf';
import './assets/fonts/Baumans/Baumans-Regular.ttf';
import './assets/fonts/Bayon/Bayon.ttf';
import './assets/fonts/Bmhanna/BM-HANNA.ttf';
import './assets/fonts/bodoni/Bodonitown.ttf';
import './assets/fonts/Bonbon/Bonbon-Regular.ttf';
import './assets/fonts/Cambo/Cambo-Regular.ttf';
import './assets/fonts/Candal/Candal.ttf';
import './assets/fonts/Capriola/Capriola-Regular.ttf';
import './assets/fonts/Caudex/Caudex-Bold.ttf';
import './assets/fonts/Caudex/Caudex-BoldItalic.ttf';
import './assets/fonts/Caudex/Caudex-Italic.ttf';
import './assets/fonts/Caudex/Caudex-Regular.ttf';
import './assets/fonts/Dekko/Dekko-Regular.ttf';
import './assets/fonts/Delius/Delius-Regular.ttf';
import './assets/fonts/Dorsa/Dorsa-Regular.ttf';
import './assets/fonts/Electrolize/Electrolize-Regular.ttf';
import './assets/fonts/Faunaone/FaunaOne-Regular.ttf';
import './assets/fonts/Fenix/Fenix-Regular.ttf';
import './assets/fonts/Gabriela/Gabriela-Regular.ttf';
import './assets/fonts/Gafata/Gafata-Regular.ttf';
import './assets/fonts/Geostar/Geostar-Regular.ttf';
import './assets/fonts/Habibi/Habibi-Regular.ttf';
import './assets/fonts/Iceberg/Iceberg-Regular.ttf';
import './assets/fonts/lato/Lato-Bold.ttf';
import './assets/fonts/lato/Lato-BoldItalic.ttf';
import './assets/fonts/lato/Lato-Italic.ttf';
import './assets/fonts/lato/Lato-Regular.ttf';
import './assets/fonts/ptsans/PT_Sans-Web-Bold.ttf';
import './assets/fonts/ptsans/PT_Sans-Web-BoldItalic.ttf';
import './assets/fonts/ptsans/PT_Sans-Web-Italic.ttf';
import './assets/fonts/ptsans/PT_Sans-Web-Regular.ttf';
import './assets/fonts/Sourcesanspro/SourceSansPro-Bold.ttf';
import './assets/fonts/Sourcesanspro/SourceSansPro-BoldItalic.ttf';
import './assets/fonts/Sourcesanspro/SourceSansPro-Italic.ttf';
import './assets/fonts/Sourcesanspro/SourceSansPro-Regular.ttf';
import './index.css';


const store = createStore(reducer, compose(
    applyMiddleware(thunk)));




ReactDOM.render(

    <Provider store={store}>
    <App/>
    </Provider>,
    document.getElementById('root'));
