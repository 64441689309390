import React, {Component} from 'react'

import cpy_d from '../assets/toolbar_Images/btn_Copy_Disabled.png';
import cpy_e from '../assets/toolbar_Images/btn_Copy_Enabled.png';

import pst_d from '../assets/toolbar_Images/btn_Paste_Disabled.png';
import pst_e from '../assets/toolbar_Images/btn_Paste_Enabled_.png';

import del_d from '../assets/toolbar_Images/btn_Delete_Disabled.png';
import del_e from '../assets/toolbar_Images/btn_Delete_Enabled_4-8.png';

import zout_d from '../assets/toolbar_Images/btn_ZoomOut_Disabled.png';
import zout_e from '../assets/toolbar_Images/btn_ZoomOut_Enabled.png';

import zin_d from '../assets/toolbar_Images/btn_ZoomIn_Disabled.png';
import zin_e from '../assets/toolbar_Images/btn_ZoomIn_Enabled_2-8.png';

import down_d from '../assets/toolbar_Images/btn_MoveDown_Disabled.png';
import down_e from '../assets/toolbar_Images/btn_MoveDown_Enabled.png';

import up_d from '../assets/toolbar_Images/btn_MoveUp_Disabled.png';
import up_e from '../assets/toolbar_Images/btn_MoveUp_Enabled.png';

import left_d from '../assets/toolbar_Images/btn_MoveLeft_Disabled.png';
import left_e from '../assets/toolbar_Images/btn_Left_Enabled-8.png';

import right_d from '../assets/toolbar_Images/btn_MoveRight_Disabled.png';
import right_e from '../assets/toolbar_Images/btn_Right_Enabled.png';

import print_d from '../assets/toolbar_Images/btn_Print_Disable_1-8.png';
import print_e from '../assets/toolbar_Images/btn_Print_Enable (1).png';

import tools_d from '../assets/toolbar_Images/btn_Settings_Disabled.png';
import tools_e from '../assets/toolbar_Images/btn_Settings_Enabled_2-8.png';

import help_d from '../assets/toolbar_Images/btn_Help_Disabled.png';
import help_e from '../assets/toolbar_Images/btn_Help_Enabled_2-8.png';

import {connect} from "react-redux";
import {Delete_Objects, TOOLBAR, ZOOMDOWN, ZOOMRESET, ZOOMUP,ACTIVATOR_ON,MOUSE_RIGHT,MOUSE_RIGHT_OFF,
    DATA_ON,MOUSE_UP,MOUSE_UP_OFF,MOUSE_DOWN,MOUSE_DOWN_OFF,MOUSE_LEFT,MOUSE_LEFT_OFF} from "../actions";
import Menu_Drop from '../../src/assets/componentImages/menu-drop.png'
import './toolbar_Style.css'

class toolbar extends Component {

    render() {
        return (

                <div  style={divStyle} >
                <div style={leftStyle}  >
                    <img alt={'menu activator'} src={Menu_Drop} style={{width:'2.4vw',height:'1.3vw',
                        marginLeft:'1vw',cursor:'pointer'}} onClick={()=>this.props.dispatch(ACTIVATOR_ON())}/>

                    <h4 style={{...textStyle,marginRight:'3.4vw',display:'flex',
                        width:'18.2vw', alignSelf:'center',justifyContent:'center'}}>{this.props.langData.label}</h4>


                </div>
                    {
                        !this.props.tool_bool ? (
                                <div style={this.props.toolIcons_bool ? {...toolStyle,backgroundColor:'#f9b785'}:{...toolStyle}}>

                                    <div onClick={this.props.toolIcons_bool ? this.props.copyClick: null}  style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                        <img data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.copy}  src={this.props.toolIcons_bool ?cpy_e:cpy_d} alt="copy" style={imgStyle}/>
                                    </div>

                                    <div onClick={this.props.toolIcons_bool && this.props.objectCopy.type ? this.props.pasteClick: null} data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.paste} style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                    <img  src={this.props.toolIcons_bool && this.props.objectCopy.type ? pst_e: pst_d} alt="paste" style={imgStyle}/>
                                    </div>

                                    <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.delete}
                                         onClick={this.props.toolIcons_bool ?()=>{this.props.dispatch(Delete_Objects());}:null}
                                         // onClick={this.props.toolIcons_bool ?()=>this.props.onDelete():null}

                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                    <img    src={this.props.toolIcons_bool ? del_e: del_d} alt="delete" style={imgStyle}/>
                                    </div>

                                    <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.zOut}
                                         // onClick={this.props.toolIcons_bool ?()=>{this.props.dispatch(ZOOMDOWN());}:null}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32',cursor:'auto'}:{...btnStyle,cursor:'auto'}}>
                                    <img   src={this.props.toolIcons_bool ? zout_d: zout_d} alt="zoom out" style={{...imgStyle,objectFit:'fill'}}/>
                                    </div>

                                    <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.zIn}
                                         // onClick={this.props.toolIcons_bool ?()=>{this.props.dispatch(ZOOMUP());}:null}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32',cursor:'auto'}:{...btnStyle,cursor:'auto'}}>
                                    <img     src={this.props.toolIcons_bool ? zin_d : zin_d} alt="zoom in" style={{...imgStyle,objectFit:'fill'}}/>
                                    </div>

                                    <div
                                        onMouseUp={this.props.toolIcons_bool ?this.props.onDownMouseUp:null}
                                        onMouseDown={this.props.toolIcons_bool ?this.props.onDownMouseDown:null}
                                        onMouseMove={this.props.toolIcons_bool ?this.props.onDownMouseUp:null}
                                        data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.mD}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                    <img   src={this.props.toolIcons_bool ? down_e: down_d} alt="down" style={imgStyle}


                                    />
                                    </div>

                                    <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.mU}
                                         onMouseUp={this.props.toolIcons_bool ?this.props.onUpMouseUp:null}
                                         onMouseDown={this.props.toolIcons_bool ?this.props.onUpMouseDown:null}
                                         onMouseMove={this.props.toolIcons_bool ?this.props.onUpMouseUp:null}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                    <img  src={this.props.toolIcons_bool ? up_e: up_d} alt="up" style={imgStyle}/>
                                    </div>

                                    <div
                                        onMouseUp={this.props.toolIcons_bool ?this.props.onLeftMouseUp:null}
                                        onMouseDown={this.props.toolIcons_bool ?this.props.onLeftMouseDown:null}
                                        onMouseMove={this.props.toolIcons_bool ?this.props.onLeftMouseUp:null}
                                        data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.mL}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                    <img  src={this.props.toolIcons_bool ? left_e : left_d} alt="left" style={imgStyle}
                                          />
                                    </div>

                                    <div
                                        onMouseUp={this.props.toolIcons_bool ?this.props.onRightMouseUp:null}
                                        onMouseDown={this.props.toolIcons_bool ?this.props.onRightMouseDown:null}
                                        onMouseMove={this.props.toolIcons_bool ?this.props.onRightMouseUp:null}
                                        data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.mR}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                    <img  src={this.props.toolIcons_bool ? right_e : right_d} alt="right" style={imgStyle}

                                    />
                                    </div>

                                    <div onClick={this.props.toolIcons_bool ?this.props.onPrint:null} data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.batch}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32'}:btnStyle}>
                                    <img  alt={'print'}  src={this.props.toolIcons_bool ? print_e : print_d} style={imgStyle}/>
                                    </div>

                                    <div onClick={this.props.settingsOn} data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.settings}
                                         style={{...btnStyle,backgroundColor:'#f58c32',cursor:'pointer'}}>
                                    <img   alt={'tools'} style={{...imgStyle,objectFit:'fill'}} src={tools_e}/>
                                    </div>

                                    <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.help}
                                         style={this.props.toolIcons_bool ? {...btnStyle,backgroundColor:'#f58c32',cursor:'auto'}:{...btnStyle,cursor:'auto'}}>
                                    <img  style={{...imgStyle,objectFit:'fill'}}   src={this.props.toolIcons_bool ? help_d: help_d} alt="help" />
                                    </div>




                                </div>

                            ):
                            (
                                    <div  style={toolStyleFill}>
                                </div>

                            )
                    }

                <div style={{...leftStyle}}>
                    <h4 style={{...textStyle,marginLeft:'3.4vw',display:'flex',
                        width:'18.2vw', alignSelf:'center',justifyContent:'center'}}>{this.props.langData.data}</h4>
                    <img alt={'menu activator'} onClick={()=>this.props.dispatch(DATA_ON())} src={Menu_Drop} style={{width:'2.4vw',height:'1.3vw',
                        marginRight:'1vw',cursor:'pointer'}} />
                </div>
                </div>


        )
    }

}
const divStyle ={
    width:'100vw',
    height:'4vw',

    display: 'flex',
    flexDirection:'row',
    zIndex: 3,


};
const leftStyle = {

    height : "4vw",width:'25vw',
    zIndex:3,
    backgroundColor:'#cccccc',
    display:'flex',
    flexDirection:'row',
    alignItems:'center',


};
const textStyle = {
    color: 'white',
    fontFamily: 'verdana',
    textAlign: "center",
    fontWeight: 'normal',
    verticalAlign: 'center',
    fontSize: '1.2vw'



};

const btnStyle = {
    padding: 0,
    margin: 0,

    // flex:'1 1 0',
    width:'4.17vw',
    display:'flex',
    border:'none',
    cursor:'pointer',
    backgroundColor:'#f9b785'
}
const imgStyle = {
    width:'100%',
    height:'100%',
    objectFit:'contain',padding:0,margin:0

};
const toolStyle = {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    height:'4vw',
    width:'50vw',
    zIndex:3,
    backgroundColor:'#f9b785'

};
const toolStyleFill = {
    padding: 0,
    margin: 0,
    display: 'flex',
    height:'100%',
    width:'50vw',
    backgroundColor:'#f58c31',

};

function mapStateToProps(state) {
    return {
        tool_bool : state.counter.Toolbar_Boolean,
        win_width: state.counter.Window_Width,
        win_height: state.counter.Window_Height,
        scale_factor: state.counter.scale_factor,
        toolIcons_bool: state.counter.toolbarIcons_On

    };
}



export default connect(mapStateToProps)(toolbar);
