import React,{useState} from 'react';
import {Modal} from "antd";
const tick = require('../assets/Tab_Images/Text/tick.png');

const DateStamp_Popup = (props) => {
    const [select,selected]= useState('')
    return (
        <Modal
            visible={props.status} width={'30vw'}
            closable={false}

            destroyOnClose={true}
            style={{

                height: '17vw', padding: 0, borderRadius: 0, top: '15vw',left:'35vw',
                display: 'flex', flexDirection: 'column',position:'absolute',zIndex:4
            }} footer={null}
            bodyStyle={{
                width: '100%', height: '17vw',
                borderRadius: 0, border: '0.2vw solid #FACE4E',
            }}>
            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',
                borderRadius: 0,backgroundColor:'white'}}>

                <div style={{
                    width: '30vw', height: '3vw', backgroundColor: '#FACE4E', fontFamily: 'verdana',fontSize:'1.2vw',
                    color: '#000000', display: 'flex', alignItems: 'center', textAlign:'center',alignSelf:'center',
                    justifyContent:'center'
                }}>
                    {props.langData.stamp}
                </div>

                <div style={{
                    color: 'black',
                    height:'4vw',
                    textAlign:'center',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    fontFamily: 'verdana',fontSize:'1.2vw',
                }}>{props.langData.stampFormat}
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    width: '100%',
                    height:'12vw',
                }}>
                    <div style={{...row2_c}}>

                        <div onClick={()=>selected('DDMMYY')}
                             style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2vw',height:'2vw',
                                 display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',borderRadius:'0.3vw'}} >
                            {
                                select === 'DDMMYY' ?
                                    <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                    null
                            }
                        </div>
                        <div style={text_Style}>DDMMYY</div>
                        <div onClick={()=>selected('DDMMYYYY')}
                             style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2vw',height:'2vw',
                                 display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',borderRadius:'0.3vw'}} >
                            {
                                select === 'DDMMYYYY' ?
                                    <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                    null
                            }
                        </div>
                        <div style={text_Style}>DDMMYYYY</div>
                    </div>
                    <div style={{...row2_c,marginTop:'1.5vw'}}>

                        <div onClick={()=>selected('YYMMDD')}
                             style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2vw',height:'2vw',
                                 display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',borderRadius:'0.3vw'}} >
                            {
                                select === 'YYMMDD' ?
                                    <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                    null
                            }
                        </div>
                        <div style={text_Style}>YYMMDD</div>
                        <div onClick={()=>selected('YYYYMMDD')}
                             style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2vw',height:'2vw',
                                 display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',borderRadius:'0.3vw'}} >
                            {
                                select === 'YYYYMMDD' ?
                                    <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                    null
                            }
                        </div>
                        <div style={text_Style}>YYYYMMDD</div>
                    </div>
                    <div   style={{display:'flex',flexDirection:'row',marginLeft:'2vw',marginTop:'1.5vw'}}>
                    <div id={select ? select:'one'} onClick={select.length > 0 ? props.applydateStamp: null} style={{...popup_btn_style}}>{props.langData.apply}</div>
                    <div onClick={props.changeStatus}  style={{...popup_btn_style,marginLeft:'2vw'}}
                         >{props.langData.cancel}
                    </div>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
const popup_btn_style = {
    backgroundColor:'#56CFE1',color:'white',width:'12vw',height:'2vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    fontFamily: 'verdana',fontSize:'1.2vw',borderRadius:'1.5vw'
}
const row2_c = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: '2vw',marginRight:'0.1vw'

};
const btn_Style_L2={
    width: '2vw',height:'2vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc',
    marginLeft:'5vw'
};
const img_row1_C1_L2 = {
    width:'90%',height:'auto', cursor:'pointer'

};
const text_Style={
    marginLeft:'0.5vw',fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
    alignSelf:'center',display:'flex'
};


export default DateStamp_Popup;
