import React, {Component} from 'react'
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";
import NumericInput from 'react-numeric-input';
import ColorPicker from 'rc-color-picker';
import './colorPicker.css';
import {ImagePicker} from 'react-file-picker'
import './toolTip_Style.css'



const aspectRatio_img_e = require('../../assets/Tab_Images/Images/btn_LockAspect_Enabled.png');
const aspectRatio_img_d = require('../../assets/Tab_Images/Images/btn_LockAspect_Disabled.png');

const stretch_img_e = require('../../assets/Tab_Images/Images/btn_Stretch_Enabled.png');
const stretch_img_d = require('../../assets/Tab_Images/Images/btn_Stretch_Disabled.png');

const line_img_e = require('../../assets/Tab_Images/Images/btn_Line_Enabled.png');
const line_img_d = require('../../assets/Tab_Images/Images/btn_Line_Disabled.png');

const square_img_e = require('../../assets/Tab_Images/Images/btn_Square_Enabled.png');
const square_img_d = require('../../assets/Tab_Images/Images/btn_Square_Disabled.png');

const circle_img_e = require('../../assets/Tab_Images/Images/btn_Circle_Enabled.png');
const circle_img_d = require('../../assets/Tab_Images/Images/btn_Circle_Disabled.png');

const btn_X_e = require('../../assets/Tab_Images/Text/lbl_X-dim-Enabled.png');
const btn_X_d = require('../../assets/Tab_Images/Text/lbl_X-dim-Disabled.png');

const btn_Y_e = require('../../assets/Tab_Images/Text/lbl_Y-dim-Enabled.png');
const btn_Y_d = require('../../assets/Tab_Images/Text/lbl_Y-dim-Disabled.png');

const left_On = require('../../assets/Tab_Images/Text/btn_Move_Left_Enable.png');
const left_Off = require('../../assets/Tab_Images/Text/btn_Move_Left_Disabled.png');

const right_On = require('../../assets/Tab_Images/Text/btn_Move_Right_Enabled.png');
const right_Off = require('../../assets/Tab_Images/Text/btn_Move_Right_Disabled.png');

const up_On = require('../../assets/Tab_Images/Text/btn_Move_Up_Enabled.png');
const up_Off = require('../../assets/Tab_Images/Text/btn_Move_Up_Disabled.png');

const down_On = require('../../assets/Tab_Images/Text/btn_Move_Down_Enabled.png');
const down_Off = require('../../assets/Tab_Images/Text/btn_Move_Down_Disabled.png');
const arrow_Up = require('../../assets/Tab_Images/Text/arrow_up.png')
const arrow_Down = require('../../assets/Tab_Images/Text/arrow_down.png')
const rotateLeft_e = require('../../assets/Tab_Images/Text/btn_Rotate_Left_Enabled.png');
const rotateLeft_d = require('../../assets/Tab_Images/Text/btn_Rotate_Left_Disabled.png');

const rotateRight_e = require('../../assets/Tab_Images/Text/btn_Rotate_Right_Enabled.png');
const rotateRight_d = require('../../assets/Tab_Images/Text/btn_Rotate_Right_Disabled.png');

const forward_e = require('../../assets/Tab_Images/Text/btn_Front_Enabled.png');
const forward_d = require('../../assets/Tab_Images/Text/btn_Front_Disabled.png');

const backward_e = require('../../assets/Tab_Images/Text/btn_Back_Enabled.png');
const backward_d = require('../../assets/Tab_Images/Text/btn_Back_Disabled.png');
const tick = require('../../assets/Tab_Images/Text/tick.png');



class shapeLayer extends Component {
    constructor(props) {
        super(props);

        // This line is important!
    }
    state = {
        lock: false,
        no_print: false,
        clock: false,
        anti_clock: false,
        forward: false,
        backward: false,
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        },
        textSelected: false


    };

    render() {


        let selectedImageBoolean = false;
        if (this.props.clickedImage >= 0 || this.props.clickedShape >= 0) {
            selectedImageBoolean = true;
        }

        return(

            <div style={{ backgroundColor: '#EEEEEE', padding: 0,
                width : "21vw",zIndex:3,minHeight:this.props.heightLayer}}>

                <ReactTooltip place="bottom" type="dark" effect="float"/>
                <div data-tip={this.props.langData.name} data-place={'right'} data-offset="{'left': 0,'right':0}" >
                <input style={{marginLeft:'2.5vw', height: '2.66vw',border:'none',marginRight:'2.5vw', width:"15.5vw",backgroundColor:'white',
                    marginTop: '1.7vw',textAlign:'center',justifyContent:'center', alignItems:'center',fontFamily: 'verdana',fontSize:'1vw'}}
                       disabled={!selectedImageBoolean}  type="text" align={'center'} value={this.props.pinImageStatus}
                       onChange={this.props.changePinImage}
                        />
                </div>
                <div style={container_Style}>


                    <div style={row2_L2}>
                        <div data-tip={this.props.langData.width} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <div style={{width:'5.2vw',height:'2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                                <input onChange={this.props.numberImageWidth} type="text" align={'center'} value={this.props.imageWidthStatus} style={{width:'4vw',outline:'none',border:'none',
                                    height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                                <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                                    <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down}
                                         onMouseUp={this.props.imageWidthMouseUp}
                                         onMouseDown={this.props.imageWidthMouseDown}/>

                                    <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up}
                                         onMouseUp={this.props.imageWidthMouseUpLess}
                                         onMouseDown={this.props.imageWidthMouseDownLess}
                                        />
                                </div>
                            </div>
                        </div>
                        <div style={{marginLeft:'0.02vw'}} data-tip={this.props.langData.height} data-place={'right'} data-offset="{'left': 0,'right':0}">

                            <div style={{width:'5.2vw',height:'2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                                <input disabled={this.props.shapeLine} onChange={this.props.numberImageHeight} type="text" align={'center'} value={this.props.imageHeightStatus}  style={{width:'4vw',outline:'none',border:'none',
                                    height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                                <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                                    <img alt={'arrowdown'} onMouseUp={this.props.imageHeightMouseUp}
                                         onMouseDown={this.props.imageHeightMouseDown}
                                         style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down}/>

                                    <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up}
                                         onMouseUp={this.props.imageHeightMouseUpLess}
                                         onMouseDown={this.props.imageHeightMouseDownLess}/>
                                </div>
                            </div>

                        </div>
                        <div style={btn_Style} data-tip={this.props.langData.aspect} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='aspect-ratio'  src={!this.props.stretchStatus && selectedImageBoolean  ? aspectRatio_img_e :aspectRatio_img_d} onClick={this.props.onAspectRatio} />
                        </div>
                        <div style={btn_Style} data-tip={this.props.langData.stretch} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='stretch'  src={this.props.stretchStatus && selectedImageBoolean  ? stretch_img_e :stretch_img_d} onClick={this.props.onStretch} />
                        </div>


                    </div>
                    <div style={row2_L2}>
                        <div style={btn_Style} id={'line'} data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.line}  onClick={this.props.createShape}>
                            <img style={img_row1_C1} alt='left'  src={line_img_e} />
                        </div>
                        <div style={btn_Style} data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.circle} id={'circle'} onClick={this.props.createShape}>
                            <img style={img_row1_C1} alt='center'  src={circle_img_e}  />
                        </div>
                        <div style={btn_Style} data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.square} id={'square'} onClick={this.props.createShape}>
                            <img style={img_row1_C1} alt='right'  src={square_img_e}  />
                        </div>
                        <div data-tip={this.props.langData.thick} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            {/*<NumericInput min={0} max={100}  style={stepper_Style} value={this.props.shapeThickness} onChange={value => this.props.changeshapeThickness(value)}  />*/}

                            <div style={{width:'5.2vw',height:'2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                                <input onChange={this.props.changeshapeThickness} type="text" align={'center'} value={this.props.shapeThickness}  style={{width:'3vw',outline:'none',border:'none',
                                    height:'90%',fontSize:'0.8vw', fontFamily:'verdana',paddingLeft:'1vw'}}/>
                                <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                                    <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={() =>this.props.changeshapeThicknessArrow((Number(this.props.shapeThickness)+1))}/>

                                    <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={() =>this.props.changeshapeThicknessArrow((Number(this.props.shapeThickness)-1))}/>
                                </div>
                            </div>

                        </div>




                    </div>
                    <div style={row2_L2}>
                        <div data-tip={this.props.langData.brColor} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <ColorPicker style={{width:'2vw',height:'2vw'}}    color={this.props.borderColor}  onClose={this.props.changeShapeBorderColor} placement="bottomRight" />
                        </div>
                        <div style={{marginLeft: '0.2vw'}} data-tip={this.props.langData.fillColor} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <ColorPicker style={{width:'2vw',height:'2vw'}}  onClose={this.props.changeShapeFillColor} placement="bottomRight" color={this.props.fillColor} />
                        </div>
                    </div>

                    <div style={row2_L2}>
                        <div style={btn_Style}>
                            <img style={img_row1_C1} alt='x' src={selectedImageBoolean ? btn_X_e: btn_X_d} />
                        </div>
                        <div data-tip={this.props.langData.pLeft} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <input style={input_style}
                                   onChange={this.props.imageXPosChange}  value={this.props.imageXPosStatus}
                                   type="text" align={'center'} />
                        </div>
                        <div style={btn_Style}>
                            <img style={img_row1_C1} alt='y'  src={selectedImageBoolean ? btn_Y_e: btn_Y_d} />
                        </div>
                        <div data-tip={this.props.langData.pTop} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <input style={input_style} type="text" align={'center'}
                                   onChange={this.props.imageYPosChange}  value={this.props.imageYPosStatus}/>
                        </div>

                    </div>
                    <div style={row2_L2}>

                        <div onMouseUp={this.props.onImageRightMouseUp} onMouseDown={this.props.onImageRightMouseDown} style={btn_Style} data-tip={this.props.langData.mR} data-place={'right'} data-offset="{'left': 0,'right':0}" >
                            <img style={img_row1_C1} alt='right'  src={selectedImageBoolean ? right_On: right_Off} />
                        </div>
                        <div onMouseUp={this.props.onImageUpMouseUp} onMouseDown={this.props.onImageUpMouseDown} style={btn_Style} data-tip={this.props.langData.mU} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='up'  src={selectedImageBoolean ? up_On: up_Off} />
                        </div>
                        <div onMouseUp={this.props.onImageDownMouseUp} onMouseDown={this.props.onImageDownMouseDown}  style={btn_Style} data-tip={this.props.langData.mD} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='down'   src={selectedImageBoolean ? down_On: down_Off} />
                        </div>
                        <div onMouseUp={this.props.onImageLeftMouseUp} onMouseDown={this.props.onImageLeftMouseDown} style={btn_Style} data-tip={this.props.langData.mL} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='left'     src={selectedImageBoolean ? left_On: left_Off} />
                        </div>

                    </div>

                    <div style={row2_L2}>

                        <div onClick={selectedImageBoolean ? this.props.clockAntiTransform: null} style={btn_Style} data-tip={this.props.langData.cclock} data-place={'right'} data-offset="{'left': 0,'right':0}" data-place={'bottom'}>
                            <img style={img_row1_C1} alt='clock'  src={selectedImageBoolean ? rotateLeft_e: rotateLeft_d} />
                        </div>
                        <div onClick={selectedImageBoolean ? this.props.clockTransform: null} style={btn_Style} data-tip={this.props.langData.clock} data-place={'right'} data-offset="{'left': 0,'right':0}" data-place={'bottom'}>
                            <img style={img_row1_C1} alt='anticlock'  src={selectedImageBoolean ? rotateRight_e: rotateRight_d} />

                        </div>
                        <div data-tip={this.props.langData.degree} data-place={'right'} data-offset="{'left': 0,'right':0}" data-place={'bottom'} style={{width:'5.32vw',height: '2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                            <input onChange={this.props.changeRotation}  disabled={!selectedImageBoolean}  value={selectedImageBoolean ? this.props.transformRotateValue: ''}   style={{width:'4vw',outline:'none',border:'none',marginLeft:'0.1vw',height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                            <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                                <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={selectedImageBoolean ? ()=>this.props.numberChangeRotation(Math.round(Number(this.props.transformRotateValue))+1): null} />

                                <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={selectedImageBoolean ? ()=>this.props.numberChangeRotation(Math.round(Number(this.props.transformRotateValue))-1):null} />

                            </div>
                        </div>
                        <div onClick={selectedImageBoolean ? this.props.bringForward: null} style={btn_Style} data-tip={this.props.langData.forward} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='forward'  src={selectedImageBoolean ? forward_e: forward_d} />

                        </div>
                        <div onClick={selectedImageBoolean ? this.props.bringBackward: null} style={btn_Style} data-tip={this.props.langData.backward} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1} alt='backward'  src={selectedImageBoolean ? backward_e: backward_d} />
                        </div>

                    </div>




                    <div style={row2_L2}>
                        <div style={{display:'flex',flexDirection:'row',flex:1}} data-tip={this.props.langData.lock} data-place={'right'} data-offset="{'left': 0,'right':0}">

                            <div style={{display:'flex',flexDirection:'row',flex:1,alignItems:'center'}}>

                                <div style={{...btn_Style,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.5vw',height:'2.5vw',
                                    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:'0.2vw'}} onClick={this.props.onImageLock}>
                                    {
                                        this.props.imageLockStatus ?
                                            <img style={{...img_row1,width:'70%'}} alt='new button'  src={tick}  />:
                                            null

                                    }
                                </div>
                                <div style={text_Style}>{this.props.langData.lockT}</div>
                            </div>

                        </div>

                        <div style={{display:'flex',flexDirection:'row',flex:1}} data-tip={this.props.langData.noPrint} data-place={'right'} data-offset="{'left': 0,'right':0}">

                            <div style={{display:'flex',flexDirection:'row',flex:1,alignItems:'center'}}>
                                <div style={{...btn_Style,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.5vw',height:'2.5vw',
                                    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:'0.2vw'}} onClick={this.props.onImageNoPrint}>
                                    {
                                        this.props.imageNoPrintStatus ?
                                            <img style={{...img_row1,width:'70%'}} alt='new button'  src={tick}  />:
                                            null

                                    }
                                </div>

                                <div style={text_Style}>{this.props.langData.noPrintT}</div>
                            </div>

                        </div>
                    </div>
                    <div style={this.props.addtoShapeSelected.length > 0 ? {...row5,cursor:'pointer'}: {...row5,cursor:'pointer',opacity:'0.5'}} onClick={this.props.addtoShapeArray}>
                        <div style={{color:'white',fontSize: '1vw',fontFamily: 'verdana',cursor:'pointer'}}>{this.props.langData.newShape}</div>
                    </div>




                </div>
            </div>

        )

    }
}

const container_Style={
    border: 'none',marginLeft:'2.5vw', marginRight:'2.5vw',
    width:'16vw',
};

const row5 = {
    flex:1, flexDirection:'row',display:'flex',width:'16vw',justifyContent:'center',alignItems:'center',backgroundColor: '#F58C32',
    height: '2.5vw', marginTop: '0.6vw',border: "0.13vw solid white",

};

const stepper_Style ={
    wrap: {
        fontSize: '1vw',
        height:'2vw',

    },
    input: {
        fontWeight: 400,
        color:'black',
        height:'2vw',
        width: '5.9vw',


    },
    arrowUp: {
        borderBottomColor: 'black'
    },
    arrowDown: {
        borderTopColor: 'black'
    }
};
const btn_Style={
    width: '2.66vw',height:'2.66vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc'
};

const input_style = {
    width:'5.2vw',height: '2.66vw',textAlign:'center', display:'flex', margin: 0, padding: 0, borderWidth: 0,
    fontFamily: 'verdana',fontSize:'0.8vw'
};

const img_row1 = {
    width:'90%',height:'90%', cursor:'pointer'

};

const text_Style={
    marginLeft:'0.5vw',fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
};
const img_row1_C1 = {
    width:'90%',height:'auto', cursor:'pointer'

};
const row2_L2 = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: '2.66vw',marginTop:'0.6vw',marginRight:'0.1vw'

};

export default connect()(shapeLayer);
