import React from 'react'
import './/drawStyle.css'
import ReactTooltip from 'react-tooltip'
import './toolTip_Style.css'

const new_btn_e = require('../../assets/Tab_Images/Draw/btn_Label_New_Enabled.png');
const new_btn_d = require('../../assets/Tab_Images/Draw/btn_Label_New_Disabled.png');

const open_btn_e = require('../../assets/Tab_Images/Draw/btn_Label_Open_Enabled.png');
const open_btn_d = require('../../assets/Tab_Images/Draw/btn_Label_Open_Disabled.png');

const save_btn_e = require('../../assets/Tab_Images/Draw/btn_Label_Save_Enabled.png');
const save_btn_d = require('../../assets/Tab_Images/Draw/btn_Label_Save_Disabled.png');


const doc_width_e = require('../../assets/Tab_Images/Draw/img_Label_Width_Enabled.png');
const doc_width_d = require('../../assets/Tab_Images/Draw/img_Label_Width_Disabled.png');

const doc_height_e = require('../../assets/Tab_Images/Draw/img_Label_Height_Enabled.png');
const doc_height_d = require('../../assets/Tab_Images/Draw/img_Label_Height_Disabled.png');


const doc_labelacross_e = require('../../assets/Tab_Images/Draw/btn_Margin_Middle_Enabled.png');
const doc_labelacross_d = require('../../assets/Tab_Images/Draw/btn_Margin_Middle_Disabled.png');

const doc_left_e = require('../../assets/Tab_Images/Draw/img_Left_Margin_Enabled.png');
const doc_left_d = require('../../assets/Tab_Images/Draw/img_Left_Margin_Disabled.png');

const doc_right_e = require('../../assets/Tab_Images/Draw/img_Right_Margin_Enabled.png');
const doc_right_d = require('../../assets/Tab_Images/Draw/img_Right_Margin_Disabled.png');

const doc_labeldown_e = require('../../assets/Tab_Images/Draw/btn_Margin_Mid_Enabled.png');
const doc_labeldown_d = require('../../assets/Tab_Images/Draw/btn_Margin_Mid_Disabled.png');

const doc_top_e = require('../../assets/Tab_Images/Draw/img_Top_Margin_Enabled.png');
const doc_top_d = require('../../assets/Tab_Images/Draw/img_Top_Margin_Disabled.png');

const doc_bottom_e = require('../../assets/Tab_Images/Draw/img_Bottom_Margin_Enabled.png');
const doc_bottom_d = require('../../assets/Tab_Images/Draw/img_Bottom_Margin_Disabled.png');

const lb_width_e = require('../../assets/Tab_Images/Draw/img_Wide_Enabled.png');
const lb_width_d = require('../../assets/Tab_Images/Draw/img_Wide_Disabled.png');

const lb_height_e = require('../../assets/Tab_Images/Draw/img_High_Enabled.png');
const lb_height_d = require('../../assets/Tab_Images/Draw/img_High_Disabled.png');


const Draw = (props) => {

    return(

            <div style={{ backgroundColor: '#EEEEEE', padding: 0,
                width : "21vw",zIndex:3,minHeight:props.heightLayer}}>

                <ReactTooltip multiline={true}  place="bottom" type="dark" effect="float"/>


                <input  data-tip={props.langData.editFile} data-place={'right'} data-offset="{'left': 0,'right':0}"
                    style={{marginLeft:'2.5vw', height: '2.66vw',border:'none',marginRight:'2.5vw', width:"15.6vw",backgroundColor:'white',
                        marginTop: '1.7vw',textAlign:'center',justifyContent:'center', alignItems:'center',
                        fontFamily: 'verdana',fontSize:'1vw'}}
                        type="text" align={'center'} value={props.fileNameStatus}
                       onChange={props.changeFileName}
                       // defaultValue={props.fileNameStatus}

                />


                        <div style={row1}>
                            <div data-place={'right'} data-offset="{'left': 0,'right':0}" onClick={props.newpopupDecider}
                                 data-tip={props.langData.createL} style={row1_btn}>
                                          <img style={img_row1} alt='new button' src={props.labelStatus ? new_btn_e : new_btn_e}/>
                                     </div>
                            <div onClick={()=>{
                                document.getElementById('zetFilePicker').click()
                            }} id={'openFileDiv'} data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={props.langData.open}
                                  style={row1_btn_mid}
                            >
                                <input onChange={props.openFile}  type="file" style={{display:'none'}}
                                       id="zetFilePicker" name="avatar"
                                       accept=".zet, .zk"/>
                                <img style={img_row1} alt='open button' src={props.labelStatus ? open_btn_e : open_btn_e}/>
                            </div>



                            <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={props.langData.save} style={row1_btn} onClick={props.saveFile}
                                  >
                                <img style={img_row1} alt='save button' src={props.labelStart ? save_btn_e: save_btn_d} />

                            </div>
                        </div>

                <div style={row2}>
                    <div style={row2_btn}  >
                        <img style={img_row2} alt='doc_width button' src={props.labelStatus ? doc_width_e: doc_width_e}   />
                        <input   data-tip={props.langData.width} data-place={'right'} data-offset="{'left': 0,'right':0}" type="text"
                                 style={input_style} value={props.width} onChange={props.changeWidth} onBlur={props.widthBlur}    />

                    </div>
                    <div style={row2_btn_mid}  >
                        <img style={img_row2} alt='doc_height button' src={props.labelStatus ? doc_height_e: doc_height_e} />
                        <input data-tip={props.langData.height} data-place={'right'} data-offset="{'left': 0,'right':0}"
                                type="text" style={input_style} value={props.height}  onChange={props.changeHeight} />

                    </div>
                    <div style={row2_btn}>
                        <img style={img_row2} alt='label_across button' src={props.labelStatus ? doc_labelacross_e: doc_labelacross_d} />
                        <input disabled type="text" style={input_style} value={props.lable_across} onChange={props.changelabelAcross} />

                    </div>
                </div>

                <div style={row2}>
                    <div style={row2_btn}>
                        <img style={img_row2} alt='doc left button' src={props.labelStatus ? doc_left_e: doc_left_d} />
                        <input disabled  type="text" style={input_style} value={props.lmargin} onChange={props.changelmar} onBlur={props.widthBlur}  />


                    </div>
                    <div style={row2_btn_mid}>
                        <img style={img_row2} alt='doc right button' src={props.labelStatus ? doc_right_e: doc_right_d} />
                        <input disabled type="text" style={input_style} value={props.rmargin} onChange={props.changermar} onBlur={props.widthBlur} />

                    </div>
                    <div style={row2_btn}>
                        <img style={img_row2} alt='doc labeldown button' src={props.labelStatus ? doc_labeldown_e: doc_labeldown_d} />
                        <input disabled type="text" style={input_style} defaultValue={props.label_down} />

                    </div>
                </div>

                <div style={row2}>
                    <div style={row2_btn}>
                        <img style={img_row2} alt='doc top button' src={props.labelStatus ? doc_top_e: doc_top_d} />
                        <input disabled type="text" style={input_style} defaultValue={'0'} />

                    </div>
                    <div style={row2_btn_mid}>
                        <img style={img_row2} alt='doc bottom button' src={props.labelStatus ? doc_bottom_e: doc_bottom_d} />
                        <input disabled type="text" style={input_style} defaultValue={'0'} />

                    </div>
                    <div style={row2_btn}>
                        <img style={img_row2} alt='label across button' src={props.labelStatus ? doc_labelacross_e: doc_labelacross_d} />
                        <input disabled type="text" align={'center'} style={input_style} defaultValue={''} />

                    </div>
                </div>
                <div style={row3}>
                    <div style={{flexDirection:'row',display:'flex',border: "0.06vw solid white"}}>
                    <img style={{height:'auto', width:'2.6vw'}} alt='new button' src={props.labelStatus ? lb_width_e:lb_width_d} />
                    <input disabled={true} readOnly type="text" align={'center'} style={{alignSelf:'center',textAlign:'center',height:'98%', width:'4.5vw',
                        borderWidth:0, backgroundColor:'#EEEEEE',fontFamily: 'verdana',fontSize:'0.8vw'}}
                     defaultValue={props.actualWidth} />
                    </div>
                    <div style={{flexDirection:'row',display:'flex',border: "0.06vw solid white"}}>

                    <img style={{height:'auto', width:'2.6vw'}} alt='new button' src={props.labelStatus ? lb_height_e: lb_height_d} />
                    <input disabled={true} readOnly type="text" align={'center'} style={{alignSelf:'center',textAlign:'center',height:'98%', width:'4.5vw',
                        borderWidth:0,backgroundColor:'#EEEEEE',fontFamily: 'verdana',fontSize:'0.8vw'}} defaultValue={props.actualHeight} />
                    </div>

                </div>
                <div data-tip={props.langData.pagesz} data-place={'right'} data-offset="{'left': 0,'right':0}"
                     style={props.labelStatus ? row4 :row4_Off}  onClick={props.createLabels}>
                    <div style={{color:'white',fontSize: '1vw',fontFamily: 'verdana',}} >{props.langData.apply}</div>
                </div>



                </div>

        )

};

const row1 = {
    flex:1, flexDirection:'row',display:'flex',width:'100%',marginLeft:'2.5vw', height: '4vw',marginTop:'0.6vw',

};

const row1_btn = {
    width:'5vw', height: '4vw',backgroundColor: '#EEEEEE',border: "0.06vw solid white",display:'flex', justifyContent:'center',alignItems:'center',cursor: 'pointer',
};

const row1_btn_mid = {
    marginLeft:'0.3vw',marginRight:'0.2vw',width:'5vw', height: '4vw',backgroundColor: '#EEEEEE',border: "0.06vw solid white",display:'flex',
    justifyContent:'center',alignItems:'center',cursor: 'pointer',
};

const row2 = {
    flex:1, flexDirection:'row',display:'flex',width:'100%',marginLeft:'2.5vw', height: '5.66vw',
    marginBottom:'0.6vw'

};

const row2_btn = {
    width:'5vw', height: '5.66vw',backgroundColor: '#EEEEEE',border: "0.06vw solid white",

    display:'flex', justifyContent:'space-around',alignItems:'center', flexDirection:'column'
};


const row2_btn_mid = {
    marginLeft:'0.2vw',marginRight:'0.2vw',width:'5vw', height: '5.66vw',backgroundColor: '#EEEEEE',
    border: "0.06vw solid white",
    display:'flex',
    justifyContent:'space-around',alignItems:'center',flexDirection:'column'
};

const input_style = {
    width:'80%',height:'1.6vw',border: "0.06vw solid black",textAlign:'center',fontFamily: 'verdana',
    fontSize:'0.8vw',outline:'none'
};
const img_row2 = {
    width:'90%',height:'auto%'

};
const img_row1 = {
    width:'80%',height:'auto'

};
const row3 = {
    flex:1, flexDirection:'row',display:'flex',width:'16.2vw',justifyContent:'space-between',alignItems:'center',
    marginLeft:'2.5vw',marginRight:'2.5vw', height: '2.1vw', marginTop: '0.5vw'

};
const row4 = {
    flex:1, flexDirection:'row',display:'flex',width:'16.2vw',justifyContent:'center',alignItems:'center',
    marginLeft:'2.5vw',marginRight:'2.5vw', height: '2.2vw',backgroundColor:'#F58C32', marginTop: '0.6vw',border: "0.06vw solid white",
    cursor:'pointer'

};
const row4_Off = {
    flex:1, flexDirection:'row',display:'flex',width:'16.2vw',justifyContent:'center',alignItems:'center',
    marginLeft:'2.5vw',marginRight:'2.5vw', height: '2.2vw',backgroundColor:'#F9B785', marginTop: '0.6vw',border: "0.06vw solid white",

};
const popup_btn_style = {
    backgroundColor:'red',color:'white',width:'4vw',height:'2vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
}


export default Draw;
