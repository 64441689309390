import React from 'react'
import Select from "react-select";
const tick = require('../../assets/Tab_Images/Text/tick.png');
const btn_Tx_e = require('../../assets/Tab_Images/Data_Var/btn_TextFixed_Enabled-8.png');
const btn_Tx_d = require('../../assets/Tab_Images/Data_Var/btn_TextFixed_Disabled-8.png');
const btn_Sf_e = require('../../assets/Tab_Images/Data_Var/btn_Suffix_Enabled-8.png');
const btn_Sf_d = require('../../assets/Tab_Images/Data_Var/btn_Suffix_Disabled-8.png');
const btn_Pf_e = require('../../assets/Tab_Images/Data_Var/btn_Prefix_Enabled-8.png');
const btn_Pf_d = require('../../assets/Tab_Images/Data_Var/btn_Prefix_Disabled-8.png');
const db_e = require('../../assets/componentImages/img_Linked-8.png')
const dt_link_img = require('../../assets/Tab_Images/Data_Var/img_Data_disabled-8.png')

function hasNumber(s) {
    return /\d/.test(s);
}
const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]


const Data_Var = (props) => {


    let selectedTextBoolean = false;
    let loadData = false
    let enableLayer = false

    if (props.textIndex > -1 || props.barcodeIndex > -1){
        enableLayer = true
        loadData=true

    }

    if (props.clickedText) {
        selectedTextBoolean = true;
    }

    let selectedBarcodeBoolean = false;
    if (props.barcodeValueStatus) {
        selectedBarcodeBoolean = true;
    }


    let chosenText = false;

    let bchosenText = false

    let activateIncrement = false;

    if (props.textIndex > -1){
        chosenText = true
        bchosenText = false
    }
    if (props.barcodeIndex > -1){
        chosenText = false
        bchosenText = true
    }
    if (props.textIndex > -1){
        activateIncrement = hasNumber(props.clickedText)
    }
    if (props.barcodeIndex > -1){
        activateIncrement = hasNumber(props.barcodeValueStatus)
    }

    return (
        <div style={{ backgroundColor: '#EEEEEE', padding: 0,
            width : "18.5vw",zIndex:3,paddingLeft:'2.5vw',minHeight:props.heightLayer}}>
            {
                loadData ?

                    <div style={{paddingTop:'1.7vw'}}>

            <div style={row2}>
                <div style={btn_Style}>
                    <img style={img_row1_C1_L2} alt='text-input'  src={chosenText && !props.dateStampStatus ? btn_Tx_d: btn_Tx_e} />
                </div>
                {
                    chosenText ?
                        <input style={input_style_Text} disabled={selectedTextBoolean && (props.dateStampStatus || props.copyStatus) || !enableLayer}
                               type="text" align={'center'}
                               onClick={props.onClickSelected}

                               // onKeyDown={props.onKeyDownSelected}

                               onChange={props.changeClickedText}
                               onKeyUp={props.onKeyUpSelected}
                               onMouseLeave={props.mouseleaveText}
                               onBlur={props.textBoxBlur}
                               value={props.clickedText}/>

                        : null
                }{  bchosenText ?
                        <input style={input_style_Text} type="text" align={'center'}
                               value={props.barcodeValueStatus}
                               onClick={props.onClickBarcodeSelected}
                               // onKeyDown={props.keyDownBarcodeText}
                               disabled={selectedBarcodeBoolean && (props.dateStampStatus || props.copyStatus) || !enableLayer}
                               onBlur={props.barcodeTextBoxBlur}
                               onChange={props.changebarcodeValue}/> : null
                }



            </div>

            <div style={{...row2_c,marginTop:'0.9vw'}}>

                <div onClick={()=>{
                    if (!props.dateStampStatus && !enableLayer) {
                        props.changeQuestion()
                    }
                }}
                     style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.66vw',height:'2.66vw',
                         display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} >
                    {
                        !(props.questionStatus && !props.dateStampStatus)&& !props.dbStatus ?
                            <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                            null

                    }
                </div>
                <div style={text_Style}>Fix Data to Screen</div>
            </div>

            <div style={row2_c}>

                <div onClick={()=>{
                    if (!props.questionStatus || !props.dateStampStatus && !enableLayer){
                        document.getElementById("questionTextID").select();

                    }
                    if (!props.dateStampStatus){
                        props.changeQuestion()

                    }
                }}
                     style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.66vw',height:'2.66vw',
                         display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} >
                    {
                        props.questionStatus ?
                            <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                            null

                    }
                </div>
                <div style={props.questionStatus ?{...quest_container,backgroundColor:'white'}:{...quest_container,backgroundColor:'#EEEEEE'}}>
                    <div style={{fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
                        alignSelf:'center',height:'100%',display:'flex'}}>Question =</div>


                {
                    chosenText ?
                        <input id={'questionTextID'} type={'text'} maxLength="20"
                               value={!props.questionStatus ? props.questionTextStatus : props.questionTextStatus}
                               style={props.questionStatus ? {
                                   fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
                                   alignSelf:'center',height:'100%',width:'7.4vw',
                                       border: 'none',
                                       outline: 'none',
                                       backgroundColor: 'white',
                                   } :
                                   {
                                       fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
                                       alignSelf:'center',height:'100%',width:'7.4vw',
                                       border: 'none',
                                       outline: 'none',
                                       fontStyle: 'italic',
                                       backgroundColor: '#EEEEEE'
                                   }}
                               onChange={props.questionTextChange}/> : null
                }
                {
                    selectedBarcodeBoolean ?
                        <input id={'questionTextID'} type={'text'} maxLength="20"
                               value={!props.questionStatus ? props.questionTextStatus : props.questionTextStatus}

                               style={props.questionStatus ? {
                                       fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
                                       alignSelf:'center',height:'100%',width:'7.4vw',
                                       border: 'none',
                                       outline: 'none',
                                       backgroundColor: 'white',
                                   } :
                                   {
                                       fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
                                       alignSelf:'center',height:'100%',width:'7.4vw',
                                       border: 'none',
                                       outline: 'none',
                                       fontStyle: 'italic',
                                       backgroundColor: '#EEEEEE'
                                   }}
                               onChange={props.questionBarcodeTextChange}/> : null

                }
                </div>
            </div>
            <div style={row2_c}>

                <div onClick={activateIncrement && !props.dateStampStatus  ? props.changeIncrement: null}
                     style={activateIncrement ? increment_checkbox: {...increment_checkbox,opacity:'0.5'}} >
                    {
                        props.incrementStatus && activateIncrement && !props.dateStampStatus ?
                            <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                            null

                    }
                </div>
                <div style={text_Style}>Auto Increment</div>
            </div>

                        <div style={row2_c}>

                            <div onClick={props.disableCopy ? null : props.copyStatusChangeEvent}
                                 style={props.disableCopy ? {...increment_checkbox,opacity:'0.5'} : increment_checkbox} >
                                {
                                   props.copyStatus ?
                                        <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                        null

                                }
                            </div>
                            {/*<div style={{width:'5vw',height:'2.66vw',backgroundColor:'red',marginLeft:'0.5vw'}}>Select New Data</div>*/}
                            <div style={{width:'13vw',marginLeft:'0.5vw'}}>
                                {
                                    props.copyStatus ?
                                        <Select placeholder={'Copy data from Field'} value={{label:props.copyDataValue}}  onChange={value => props.copyDataChange(value)} styles={customStyles} options={props.copySelect}   /> :
                                        <Select  placeholder={'Copy data from Field'} value={'Copy data from Field'} isDisabled styles={customStyles} options={options}   />
                                }

                            </div>
                        </div>

                        <div style={row2_c}>

                <div onClick={props.changedateStamp}
                    style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.66vw',height:'2.66vw',
                        display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} >
                    {
                        props.dateStampStatus ?
                            <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                            null

                    }
                </div>
                <div style={text_Style}>Date</div>
            </div>



            <div style={row2_c}>
                <div style={{display:'flex',flexDirection:'row'}}>

                    <div style={btn_Style_Img}>
                        <img style={img_row1_C1_L2} alt='prefix'  src={chosenText && props.dateStampStatus ? btn_Pf_d : btn_Pf_e}  />

                    </div>

                    <input style={input_style_C2} type="text" align={'center'}  disabled={chosenText ? selectedTextBoolean && props.dateStampStatus :
                        selectedBarcodeBoolean && props.dateStampStatus}
                           onKeyDown={props.onKeyDownPrefix}
                           onChange={props.changeClickedPrefixText}
                           onKeyUp={props.onKeyUpPrefix}
                           value={props.prefixStatus} />
                </div>
                <div style={{display:'flex',flexDirection:'row'}}>

                    <div style={btn_Style_Img}>
                        <img style={img_row1_C1_L2} alt='suffix'  src={chosenText && props.dateStampStatus ? btn_Sf_d : btn_Sf_e} />
                    </div>

                    <input style={input_style_C2} type="text" align={'center'}  disabled={chosenText ? selectedTextBoolean && props.dateStampStatus :
                        selectedBarcodeBoolean && props.dateStampStatus}   onClick={props.onClickSuffix} onKeyDown={props.onKeyDownSuffix}
                           onChange={props.changeClickedSuffixText} onKeyUp={props.onKeyUpSuffix}    value={props.suffixStatus} />
                </div>
            </div>
                        <div style={row2_c}>

                            <img alt={'db_link'}  src={dt_link_img}
                                 style={{...increment_checkbox,cursor:'default',border:'none'}} />
                                {/*{*/}
                                {/*    props.incrementStatus && activateIncrement && !props.dateStampStatus ?*/}
                                {/*        <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:*/}
                                {/*        null*/}

                                {/*}*/}
                            <div style={{display:'flex',flexDirection:'column'}}>
                                <div style={text_Style}>USE DATA LINK</div>
                                <div  style={{...text_Style,fontSize:'0.75vw'}}>PRO VERSION ONLY</div>

                            </div>
                        </div>

                        <div style={row2_c}>

                            <div
                                onClick={props.dbData.length > 0  ? props.changeDBEnable: props.popupCSV}

                                 style={{...increment_checkbox}} >
                                {
                                    props.dbStatus && props.labelDB.length > 0 ?
                                        <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                        null

                                }
                            </div>
                            <div style={{width:'13vw',marginLeft:'0.5vw'}}>
                                {
                                    props.dbStatus ?
                                        <Select  maxMenuHeight={window.innerHeight*0.12}
                                                 menuPlacement="auto"
                                                 placeholder={'Select Data Field'} value={{label:props.labelDB}} styles={customStyles}  onChange={value => props.dbDataChange(value)}  options={props.dbSelect}   /> :
                                        <Select  placeholder={'Select Data Field'} value={'Select Data Field'} isDisabled styles={customStyles} options={options}   />
                                }

                            </div>
                        </div>

                        {/*<div style={row2_c}>*/}

            {/*<div style={{...btn_Style,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.66vw',height:'2.66vw',*/}
            {/*    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:'0.2vw'}} onClick={props.changeCheckDigit}>*/}
            {/*    {*/}
            {/*        props.checkDigitStatus ?*/}
            {/*            <img style={{...img_row1_C1,width:'70%'}} alt='new button'  src={tick}  />:*/}
            {/*            null*/}

            {/*    }*/}
            {/*</div>*/}
            {/*<div style={{...text_Style}}>Check Digit</div>*/}
            {/*</div>*/}
                    </div>

            :

                    <div style={{width:'16vw',fontSize:'1vw',fontFamily: 'verdana',display:'flex',justifyContent:'center',
                        alignSelf:'center',textAlign:'center',fontWeight:700,paddingTop:'1.7vw',
                        paddingRight:'2.5vw',color:'black'}}>Click on a text field to create data links
                    </div>
            }




        </div>
    )

}
const row2_c = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: '2.66vw',marginTop:'0.6vw',marginRight:'0.1vw'

};
const btn_Style_Img={
    width: '2.66vw',height: 'auto', display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor:'#cccccc'
};
const text_Style={
    marginLeft:'0.5vw',fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
    alignSelf:'center',height:'100%',display:'flex'
};


const row2 = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: 'auto',marginRight:'0.1vw'

};
const btn_Style={
    width: '2.66vw',height:'2.66vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc'
};

const input_style_Text = {
    width:'14vw',height: '2.66vw',textAlign:'center', display:'flex', margin: 0, padding: 0, borderWidth: 0, outline: 'none',fontFamily: 'verdana',
    fontSize:'1vw',
};
const input_style_C2 = {
    width:'5.8vw',height: '2.66vw',textAlign:'center', display:'flex', margin: 0, padding: 0, borderWidth: 0,fontFamily: 'verdana',
    fontSize:'1vw',outline:'none'
};
const btn_Style_L2={
    width: '2.66vw',height:'2.66vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc'
};
const img_row1_C1_L2 = {
    width:'90%',height:'auto', cursor:'pointer'

};
const increment_checkbox = {
    ...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.66vw',height:'2.66vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'
}
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 0,
        border:'none',
        borderRadius:0,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
        width:'2.26ex',
        strokeWidth:1,

    }),
    clearIndicator: base => ({
        ...base,
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        fontSize:'1vw',
        fontFamily:'verdana',
        paddingLeft:'0.5vw'
    }),
    placeholder: base => ({
        ...base,
        fontSize:'1vw',
        fontFamily:'verdana',
        color:'black',

    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        height:'2.66vw',
        border:'none'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
        height:'2.66vw'
    }),
    indicatorsContainer: base => ({
        ...base,
        padding: 0,
        height:'2.66vw',
    }),
    menuList: base => ({
        ...base,
        fontSize:'1vw',
        fontFamily:'verdana',
        minHeight:'15vw'
    }),

};
const quest_container = {
    marginLeft:'0.5vw',fontSize:'1vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
    alignSelf:'center',height:'100%',display:'flex',flexDirection:'row',backgroundColor:'white'
}
export default Data_Var;
