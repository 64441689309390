import React from "react";


export function getDrawProps(width_num, height_num,scale_factor) {
    window.addEventListener("resize", updated);
    let rb = document.documentElement.clientWidth
    let rsz = rb * 0.095
    let h_z = document.documentElement.clientHeight - rsz;

    let width =  Number(window.innerWidth);
    let height =  Number(window.outerHeight);

    function  updated (){
        width =  Number(window.innerWidth);
        height =  Number(window.innerWidth/2);
    }




    if (Number(width_num) > Number(height_num)){
        const lbx_ratio = Number(width_num)/ Number(height_num);
        const stg_yh = (h_z - (width * 0.4 /lbx_ratio))/2 ;
        let stg_width = width * 0.5 * scale_factor;
        let stg_height = stg_yh * 2+ (width * 0.4 /lbx_ratio) * scale_factor;
        let stg_x = width * 0.05 * scale_factor;
        let stg_y = width * 0.05 * scale_factor;



        if (scale_factor < 1){
            stg_width = width * 0.5;
            stg_height = stg_yh * 2+ (width * 0.4 /lbx_ratio)
            stg_x = ((width * 0.5 - (width * 0.5 * scale_factor))/2 )
        }
        stg_width = width * 0.4
        stg_height = (width * 0.4 /lbx_ratio)

        let clrd_w = width * 0.4
        let clrd_h = (width * 0.4 /lbx_ratio)

        let rtz = Math.round(clrd_h/clrd_w)

        if (rtz === 1){
            clrd_w = width * 0.3
            clrd_h = (width * 0.3 /lbx_ratio)
            stg_x = width * 0.1 * scale_factor;
            if (scale_factor < 1){
                stg_x = ((width * 0.5 - (width * 0.3 * scale_factor))/2 )
            }
        }


        return [Number(stg_x), Number(stg_y), Number(clrd_w), Number(clrd_h)]
    }
    if (Number(width_num) === Number(height_num)) {

        const stg_yh = (h_z - (width * 0.3)) / 2;
        let stg_width = width * 0.5 * scale_factor;
        let stg_x = width * 0.1 * scale_factor
        let stg_y = width * 0.05 * scale_factor;

        let stg_height = stg_yh * 2 + (width * 0.3) * scale_factor;
        if (scale_factor < 1) {
            stg_width = width * 0.5;
            stg_height = stg_yh * 2 + (width * 0.3);
            stg_x = ((width * 0.5 - (width * 0.3 * scale_factor)) / 2)
        }
        stg_width = width * 0.3
        stg_height = (width * 0.3)
        return [Number(stg_x), Number(stg_y), Number(stg_width), Number(stg_height)]
    }

    if (Number(width_num) < Number(height_num)) {
        const lbx_ratio = Number(height_num) / Number(width_num);
        let lb_height = width * 0.3;
        const lb_width = width * 0.3 / lbx_ratio;
        if (lb_height >= h_z) {
            lb_height = lb_height * 0.9
        }
        let stg_x = ((width * 0.5) - (lb_width)) / 2;
        let stg_y = width * 0.05 * scale_factor;

        const stg_yh = (h_z - (lb_height)) / 2;
        let stg_width = width * 0.5 * scale_factor;
        let stg_height =h_z * scale_factor;
        if (scale_factor < 1) {
            stg_width = width * 0.5;
            stg_height = h_z;
            stg_x = ((width * 0.5 - lb_width) / 2)
        }
        stg_width = lb_width
        stg_height = lb_height
        return [Number(stg_x), Number(stg_y), Number(stg_width), Number(stg_height)]
    }
}


export function textMoveY(){

}
