import React,{useState,useEffect} from 'react';
import {Modal} from "antd";
import cancelIcon from '../assets/images/black_cancel.png'
import './questionPrintStyle.css'
import { css } from "@emotion/core";
import GridLoader	 from "react-spinners/GridLoader";
import Draggable from "react-draggable";
const arrow_Up = require('../assets/Tab_Images/Text/arrow_up.png')
const arrow_Down = require('../assets/Tab_Images/Text/arrow_down.png')
const btn_listAdd = require('../assets/toolbar_Images/btn_List_Disabled-8.png')
const lock = require('../assets/toolbar_Images/btn_locked-8.png')
const unlock = require('../assets/toolbar_Images/btn_unlocked-8.png')


const QuestionPrint = (props) => {
    const [print,setPrint] = useState(false)
    const [pages,setPages] = useState(1)
    const[loadCol,setLoadCol] = useState(false)
    const [offset,setOffset] = useState(0)
    const [mxWidth, setMxWidth] = useState(0)
    const [disabled,setDisabled] = useState(false)
    const [sortArr,setSortArr] = useState([])
    const [reload,setReload] = useState(false)
    const [totalpages,setTotalPages] = useState(0)
    const [qtyClick,setQtyClick] = useState(false)

    useEffect(() => {
        setSortArr(props.sortArray)
        checkLoad()
        if (qtyClick){
            document.getElementById('qtyValue').focus()

        }
        document.addEventListener('mouseover',function(e){
            var cursor = e.target.style.cursor;
            if (props.changeStatus){
                setDisabled(cursor === 'ew-resize')
            }
        });
        if (loadCol && document.getElementById('column2')){
            if (document.getElementById('column2').offsetLeft > document.getElementById('col2box').offsetLeft){
                setMxWidth((window.innerWidth*0.38)-document.getElementById('column2').offsetLeft)
            }else {
                setMxWidth((window.innerWidth*0.38)-document.getElementById('col2box').offsetLeft)
            }

        }


    });

    const checkLoad = ()=>{
        if (sortArr.length > 0 && reload){
            for (let m = 0; m < props.txtData.length;m++){
                document.getElementById('txtDataInp'+m).tabIndex = -1
            }
            for (let m = 0; m < props.btxtData.length;m++){
                document.getElementById('btxtDataInp'+m).tabIndex = -1
            }
        }

        if (sortArr.length > 0 && reload){
            let r= props.txtData.length+props.btxtData.length
            for (let m = 0; m < props.txtData.length;m++){
                if(document.getElementById('txtDataInp'+m).tabIndex === -1){
                    r = r-1
                }
            }
            for (let m = 0; m < props.btxtData.length;m++){
                if(document.getElementById('btxtDataInp'+m).tabIndex === -1){
                    r = r-1
                }
            }
            if (r === 0){
                let rx = reversSort(sortArr[0][0],sortArr[0][1])[1]

                if (sortArr[0][0] === 'txt'){
                    if (document.getElementById('txtDataInp'+rx)){
                        document.getElementById('txtDataInp'+rx).tabIndex = -1
                        document.getElementById('txtDataInp'+rx).focus()
                    }

                }
                if (sortArr[0][0] === 'btxt'){
                    if (document.getElementById('btxtDataInp'+rx)){
                        document.getElementById('btxtDataInp'+rx).tabIndex = -1
                        document.getElementById('btxtDataInp'+rx).focus()

                    }

                }

            }
            setReload(false)

        }
        if (props.txtData.length > 0 && !reload){
            let r= props.txtData.length+props.btxtData.length
            for (let m = 0; m < props.txtData.length;m++){
                if (document.getElementById('txtDataInp'+m)){
                    if(document.getElementById('txtDataInp'+m).tabIndex === -1){
                        r = r-1
                    }
                }}
            for (let m = 0; m < props.btxtData.length;m++){
                if (document.getElementById('btxtDataInp'+m)){
                    if(document.getElementById('btxtDataInp'+m).tabIndex === -1){
                        r = r-1
                    }
                }}
                if (sortArr.length > 0 && r === 0){
                    for (let m = 0; m < props.txtData.length;m++){
                        if (document.getElementById('txtDataInp'+m)){
                            document.getElementById('txtDataInp'+m).tabIndex = -1

                        }}
                    for (let m = 0; m < props.btxtData.length;m++){
                        if (document.getElementById('btxtDataInp'+m)){
                            document.getElementById('btxtDataInp'+m).tabIndex = -1

                        }}
                    let rx = reversSort(sortArr[0][0],sortArr[0][1])[1]

                    if ((document.getElementById('txtDataInp'+rx) || document.getElementById('btxtDataInp'+rx)) && !qtyClick) {

                        if (sortArr[0][0] === 'txt'){
                            document.getElementById('txtDataInp' + rx).tabIndex = 0
                            document.getElementById('txtDataInp' + rx).focus()
                        }
                        if (sortArr[0][0] === 'btxt'){
                            document.getElementById('btxtDataInp' + rx).tabIndex = 0
                            document.getElementById('btxtDataInp' + rx).focus()
                        }

                    }
                }
            }
        }


    const checkSort =(c,b)=>{
        for (let i =0; i < sortArr.length;i++){
            if (sortArr[i][1] === b && c === sortArr[i][0]){
                if (i+1 < sortArr.length){
                    return reversSort(sortArr[i+1][0],sortArr[i+1][1])

                } else {

                    return reversSort(sortArr[0][0],sortArr[0][1])

                }
            }
        }
    }
    const reversSort =(c,b)=>{
        if (c === 'txt'){
            for (let i =0;i < props.txtData.length;i++){
                if (props.txtData[i].id === b){
                    return [c,i]
                }
            }
        }
        if (c === 'btxt'){
            for (let i =0;i < props.btxtData.length;i++){
                if (props.btxtData[i].id === b){
                    return [c,i]
                }
            }
        }

    }


    return (
        <Modal id={'qpPrint'}

            visible={props.status} width={'50vw'}

            closable={false}
               afterClose={props.closingIcon}
            destroyOnClose={true}
            style={{
                height: '10vw', padding: 0, borderRadius: 0, top: '6.5vw',left:'25vw',
                display: 'flex', flexDirection: 'column',position:'absolute',zIndex:4,


            }} footer={null}
            bodyStyle={loadCol ? ant_body: {...ant_body,border:'none'}}>
            {
                !loadCol ?
                    <GridLoader
                    css={override}
                    size={30}
                    color={'#838383'}
                    loading={true}
                /> : null
            }
            {
                props.status ?


                    <div onLoad={(e)=>{
                        if (!loadCol){

                            if (document.getElementById('column2').offsetLeft > document.getElementById('col2box').offsetLeft){
                                setOffset(document.getElementById('column2').offsetLeft)
                                setMxWidth((window.innerWidth*0.38)-document.getElementById('column2').offsetLeft)
                                setLoadCol(true)
                                return
                            }
                            if (document.getElementById('column2').offsetLeft < document.getElementById('col2box').offsetLeft){

                                setOffset(document.getElementById('col2box').offsetLeft)
                                setMxWidth((window.innerWidth*0.38)-document.getElementById('col2box').offsetLeft)
                                setLoadCol(true)
                            }
                        }



                    }} style={loadCol ? root_div:{...root_div,opacity:0}}>
                        <div style={{display:'flex',flexDirection:'row',width:'50vw',backgroundColor: '#FACE4E'}}>

                            <div style={{...question_txt_style}}>{props.langData.qPrint}</div>
                            <img alt={'cancelIcon'} src={cancelIcon} style={cancelIconStyle} onClick={()=>{
                                setLoadCol(false)
                                setPages(1)
                                setPrint(false)
                                setTotalPages(0)
                                setOffset(0)
                                setMxWidth(0)
                                props.changeStatus()
                            }}/>
                        </div>
                        <div style={{marginTop:'0.625vw',marginLeft:'34vw'}}>


                        </div>

                        <div  style={{...col2_div}}>

                            <div id={'column1'} style={{display:'flex',flexDirection:'column',height:'auto'}}>

                                {
                                    props.txtData.map((item,index) => {

                                        return (
                                            <Draggable disabled={props.lockQPStatus}
                                                defaultPosition={{x:item.txt.qTextdeltaX*window.innerWidth/100, y:item.txt.qTextdeltaY*window.innerWidth/100}}
                                                onDrag={(e, ui)=>props.handleDragQtext(e, ui,item.id)}
                                                enableUserSelectHack={false}>
                                                <div style={txt_div}>
                                                    <div style={{fontFamily: 'verdana', fontSize:'1.2vw'}}>{item.txt.questionText}</div>
                                                </div>
                                            </Draggable>
                                        )
                                    })
                                }

                                {
                                    props.btxtData.map(item=>{
                                        return (
                                            <Draggable disabled={props.lockQPStatus}
                                                defaultPosition={{x:item.txt.qTextdeltaX*window.innerWidth/100, y:item.txt.qTextdeltaY*window.innerWidth/100}}
                                                onDrag={(e, ui)=>props.handleDragQBtext(e, ui,item.id)}
                                                enableUserSelectHack={false}>
                                                <div style={{...txt_div}}>
                                                    <div style={{fontFamily: 'verdana', fontSize:'1.2vw' }}> {item.txt.questionText}</div>
                                                </div>
                                            </Draggable>
                                        )
                                    })
                                }

                            </div>
                            <div id={'column2'} style={loadCol ? document.getElementById('column2') ? document.getElementById('column2').offsetLeft < document.getElementById('col2box').offsetLeft ?
                                {...col2,paddingLeft:loadCol ? 0: 0,width:'40vw',maxWidth:'40vw'}:col2:col2: col2} >
                                {
                                    props.txtData.map((item,indx) => {

                                        let ttx = item.txt.prefix+item.txt.text+item.txt.suffix
                                        if (item.txt.increment){
                                            ttx =  addAutoIncrement(item.txt.prefix+item.txt.text+item.txt.suffix, props.addListCount)
                                        }
                                        return (
                                            <Draggable disabled={disabled || props.lockQPStatus}
                                                       defaultPosition={{x:item.txt.qInputdeltaX*window.innerWidth/100, y:item.txt.qInputdeltaY*window.innerWidth/100}}
                                                       onDrag={(e, ui)=>{
                                                           props.handleDragQInputtext(e, ui,item.id)}}
                                                       enableUserSelectHack={false}>
                                                <div onMouseLeave={(e)=>{
                                                    if (Math.round(mxWidth) > Math.round(e.currentTarget.clientWidth-window.innerWidth*0.004)-1){
                                                        props.onChangeQWidth(item.id,(e.currentTarget.clientWidth-(window.innerWidth*0.004)))}
                                                    if (Math.round(mxWidth)-1 < Math.round(e.currentTarget.clientWidth-window.innerWidth*0.004)){
                                                        props.onChangeQWidth(item.id,(e.currentTarget.clientWidth-(window.innerWidth*0.004)))}
                                                }} onKeyUp={(e)=>{

                                                    if (e.key === 'Tab' && sortArr.length > 0) {
                                                        let v = null

                                                        for (let b=0;b < props.txtData.length;b++){
                                                            if (document.activeElement === document.getElementById('txtDataInp'+b)){
                                                                v = checkSort('txt',props.txtData[b].id)

                                                            }
                                                        }

                                                        if (v !== null){
                                                            for (let m = 0; m < props.btxtData.length;m++){
                                                                if (document.getElementById('btxtDataInp'+m)){
                                                                    document.getElementById('btxtDataInp'+m).tabIndex = -1
                                                                }}

                                                            for (let m = 0; m < props.txtData.length;m++){
                                                                if (document.getElementById('txtDataInp'+m)){
                                                                    document.getElementById('txtDataInp'+m).tabIndex = -1
                                                                }}
                                                            if (v[0] === 'txt'){
                                                                document.getElementById('txtDataInp'+v[1]).tabIndex = 0
                                                            }
                                                            if (v[0] === 'btxt'){
                                                                document.getElementById('btxtDataInp'+v[1]).tabIndex = 0
                                                            }

                                                        }

                                                    }}}



                                                     id={'txtData'+indx}
                                                     style={Math.round(item.txt.qWidthNow) === 40 ? {...input_div,width:mxWidth+'px',maxWidth:'30vw',display:'flex'}:
                                                         {...input_div, width:item.txt.qWidth+'vw',maxWidth:'30vw',display:'flex'}}>
                                                    <input id={'txtDataInp'+indx}
                                                        onChange={(e)=>props.onChangeTxt(item.id,e.currentTarget.value)}
                                                        value={ttx}
                                                           tabIndex={sortArr.length === 0 ? 0 : -1}
                                                           // tabIndex={sortArr.length === 0 ? 0: sortArr[0][0] === props.txtData[indx].id ? 0: -1}
                                                        style={{fontFamily: 'verdana', fontSize:'1vw',border:'none',outline:'none',width:'100%',height:'1.8vw'}}/>
                                                </div>
                                            </Draggable>
                                        )
                                    })
                                }
                                {
                                    props.btxtData.map((item,indx) =>{
                                        let ttx = item.txt.prefix+item.txt.text+item.txt.suffix
                                        if (item.txt.increment){
                                            ttx =  addAutoIncrement(item.txt.prefix+item.txt.text+item.txt.suffix, props.addListCount)
                                        }
                                        return (
                                            <Draggable disabled={disabled || props.lockQPStatus}
                                                       defaultPosition={{x:item.txt.qInputdeltaX*window.innerWidth/100, y:item.txt.qInputdeltaY*window.innerWidth/100}}
                                                       onDrag={(e, ui)=>props.handleDragQInputBtext(e, ui,item.id)}
                                                       enableUserSelectHack={false}>
                                                <div  onMouseLeave={(e)=>{
                                                    if (Math.round(mxWidth) > Math.round(e.currentTarget.clientWidth-window.innerWidth*0.004)-1){
                                                        props.onChangeQWidthBtx(item.id,(e.currentTarget.clientWidth-(window.innerWidth*0.004)))}
                                                    if (Math.round(mxWidth)-1 < Math.round(e.currentTarget.clientWidth-window.innerWidth*0.004)){
                                                        props.onChangeQWidthBtx(item.id,(e.currentTarget.clientWidth-(window.innerWidth*0.004)))}
                                                }}

                                                      onKeyUp={(e)=>{
                                                          if (e.key === 'Tab' && sortArr.length > 0) {
                                                              let v = null

                                                              for (let b=0;b < props.btxtData.length;b++){
                                                                  if (document.activeElement === document.getElementById('btxtDataInp'+b)){
                                                                      v = checkSort('btxt',props.btxtData[b].id)

                                                                  }
                                                              }
                                                              if (v !== null){
                                                                  for (let m = 0; m < props.btxtData.length;m++){
                                                                      if (document.getElementById('btxtDataInp'+m)){
                                                                          document.getElementById('btxtDataInp'+m).tabIndex = -1
                                                                      }}

                                                                  for (let m = 0; m < props.txtData.length;m++){
                                                                      if (document.getElementById('txtDataInp'+m)){
                                                                          document.getElementById('txtDataInp'+m).tabIndex = -1
                                                                      }}
                                                                  if (v[0] === 'txt'){
                                                                      document.getElementById('txtDataInp'+v[1]).tabIndex = 0
                                                                  }
                                                                  if (v[0] === 'btxt'){
                                                                      document.getElementById('btxtDataInp'+v[1]).tabIndex = 0
                                                                  }

                                                              }

                                                          }}}

                                                      id={'btxtData'+indx}
                                                      style={item.txt.qWidthNow === 40 ? {...input_div,width:mxWidth+'px',maxWidth:'30vw',display:'flex'}: {...input_div,
                                                          width:item.txt.qWidth+'vw',maxWidth:'30vw',display:'flex'}}>
                                                    <input id={'btxtDataInp'+indx}
                                                        onChange={(e)=>props.onChangeBtx(item.id,e.currentTarget.value)}
                                                        value={ttx} tabIndex={sortArr.length === 0 ? 0 : -1}
                                                        style={{fontFamily: 'verdana', fontSize:'1vw',border:'none',outline:'none',width:'100%',height:'2vw'}}/>
                                                </div>
                                            </Draggable>
                                        )
                                    })
                                }

                            </div>
                        </div>


                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'2.5vw'}}>
                            <img onClick={props.onChangeLockP} alt={'locked'} src={!props.lockQPStatus ? unlock: lock} style={{width:'3vw',height:'3vw',marginLeft:'2vw',cursor:'pointer',
                                marginTop:'-0.5vw'}}/>

                            <div style={{fontFamily: 'verdana', fontSize:'1.2vw',textAlign:'right',marginLeft:'1vw',
                           width:'10.3vw'}}>{props.langData.qty}</div>
                            {/*{*/}
                            {/*    loadCol ?*/}
                            <div id={'col2box'}  style={loadCol ?
                                {...col2box_Style,marginLeft:'18.01vw',position:'absolute'}: {...col2box_Style}} >
                                <input disabled={props.checkCSVData}
                                    onFocus={()=>{
                                        if (sortArr.length > 0 && !qtyClick){
                                            for (let m = 0; m < props.txtData.length;m++){

                                                if(document.getElementById('txtDataInp'+m).tabIndex === 0){
                                                    document.getElementById('txtDataInp'+m).focus()
                                                    document.getElementById('txtDataInp'+m).select()

                                                }
                                            }
                                            for (let m = 0; m < props.btxtData.length;m++){
                                                if(document.getElementById('btxtDataInp'+m).tabIndex === 0){
                                                    document.getElementById('btxtDataInp'+m).focus()
                                                    document.getElementById('btxtDataInp'+m).select()

                                                }
                                            }
                                        }
                                        if (sortArr.length === 0 && !qtyClick){
                                            if (props.txtData.length){
                                                document.getElementById('txtDataInp'+0).focus()
                                                document.getElementById('txtDataInp'+0).select()
                                            } else {
                                                if (props.btxtData.length){
                                                    document.getElementById('btxtDataInp'+0).focus()
                                                    document.getElementById('btxtDataInp'+0).select()
                                                }
                                            }

                                        }

                                    }}

                                    onBlur={()=>setQtyClick(false)}
                                    onClick={(e)=>{
                                        if (!qtyClick){
                                            setQtyClick(true)
                                        }
                                    }
                                    }
                                 id={'qtyValue'} type={'number'}  onMouseLeave={()=>{
                                    if (pages < 1){
                                        setPages(1)
                                    }
                                }} value={pages} onChange={(e)=>{
                                    let r = document.getElementById('qtyValue')
                                    if (r.value === ''){
                                        setPages('')
                                    }
                                    else{
                                        setPages(Number(r.value))
                                    }

                                }}   style={{width:'3vw',outline:'none',border:'none',textAlign:'left',marginLeft:'1vw',
                                    height:'1.8vw',fontSize:'1vw', fontFamily:'verdana',color:'black',}} defaultValue={pages} />
                                <div style={{display:'flex',flexDirection:'column',width:'1.32vw',height:'2vw',justifyContent:'space-around',
                                    alignItems:'center'}}>
                                    <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={props.checkCSVData ? null :()=>setPages(pages+1)} />

                                    <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={props.checkCSVData ? null :pages > 1 ?()=> setPages(pages-1): null}  />

                                </div>
                                <img alt={'list_add'} src={btn_listAdd} style={{width:'3vw',height:'2vw',marginLeft:'2.5vw'}}/>
                                <div style={{display:'flex',alignItems:'center',height:'2vw',marginLeft:'0.8vw',}}>
                                    <div style={{color:'#666666',fontWeight:500,fontSize:'1vw', fontFamily:'verdana',}}>{print && !props.reprint.label ? totalpages: 0}</div>

                                </div>
                            </div>
                            <div
                                className={'addtoListStyle'}
                                style={props.checkCSVData ? {position:'absolute',marginLeft:'34.5vw',opacity:'0.5'} :{position:'absolute',marginLeft:'34.5vw'}}
                                onClick={props.checkCSVData ? null :
                                    ()=>{
                                        setPrint(true)
                                        setTotalPages(totalpages+pages)
                                        props.addList(pages)
                                        setPages(1)
                                    }} >{props.langData.addList}</div>

                        </div>


                        <div style={{display:'flex',flexDirection:'row', height:'3vw', width:'50vw',marginTop:'1.5vw',
                            marginLeft:'1.5vw'}}>
                            <div onClick={props.checkCSVData ? null :()=>{
                                for (let i =0;i < props.txtData.label;i++){
                                    document.getElementById('txtDataInp'+i).tabIndex = '-1'
                                }
                                props.onFlip()
                                setReload(true)
                            }} style={props.checkCSVData ? {...popup_btn_style,fontFamily: 'verdana',fontSize:'1vw',opacity:'0.5'} :{...popup_btn_style,fontFamily: 'verdana',fontSize:'1vw'}}>RESET TAB INDEX</div>
                            <div style={props.checkCSVData ? {...popup_btn_style,marginLeft:'1.5vw',opacity:0.5, fontFamily: 'verdana',fontSize:'1vw'} :props.reprint.label ?{...popup_btn_style,marginLeft:'1.5vw',fontFamily: 'verdana',fontSize:'1vw'}:
                                {...popup_btn_style,marginLeft:'1.5vw',opacity:0.5, fontFamily: 'verdana',fontSize:'1vw'}} onClick={props.checkCSVData ? null :props.reprint.label  ? ()=>{
                                    setPrint(true)
                                    setPages(1)
                                    props.reprintData()
                                }
                                : null}>{props.langData.printPrev}</div>


                            <div style={props.checkCSVData ? {...popup_btn_style,marginRight:'1vw',marginLeft:'1.5vw',
                                fontFamily: 'verdana',fontSize:'1vw'} : print && !props.reprint.label ?{...popup_btn_style,marginRight:'1vw',marginLeft:'1.5vw',
                                    fontFamily: 'verdana',fontSize:'1vw'}:
                                {...popup_btn_style,marginRight:'1vw',marginLeft:'1.5vw',opacity:0.5,
                                    fontFamily: 'verdana',fontSize:'1vw'}} onClick={props.checkCSVData ? ()=>props.printQuestion(pages) :print && !props.reprint.label ? ()=>{
                                setPrint(false)
                                setLoadCol(false)
                                setTotalPages(0)
                                props.printQuestion(pages)
                                setPages(1)
                            }: null}>{props.langData.print}</div>
                        </div>


                    </div> : null
            }

        </Modal>
    )
}
const addAutoIncrement = (ps,cnt) =>{

    let yp = (checkListAutoIncrement(ps))
    if (yp[1]+1 === ps.length){
        if (yp[0] === 0){
            let rxt = Number(ps)
            let yl = rxt+cnt
            return yl.toString()

        }else {
            let rxt = Number(ps.substring(yp[0],yp[1]+1))
            let txt = ps
            let yl = rxt+cnt

            return txt.substring(0,yp[0])+yl.toString()
        }

    }
    else{
        if (yp[0] === 0){
            let rxt = Number(ps.substring(yp[0],yp[1]+1))
            let txt = ps
            let yl = rxt+cnt
            return  yl.toString()+txt.substring(yp[1]+1, txt.length)
        }else {
            let rxt = Number(ps.substring(yp[0],yp[1]+1))
            let txt = ps
            let yl = rxt+cnt

            return txt.substring(0,yp[0])+yl.toString()+txt.substring(yp[1]+1, txt.length)
        }
    }

}
const checkListAutoIncrement = (r) =>{
    let gx = false
    let px = 0
    for (var i = r.length ; i >= 0; i -- ){
        let isnum = /^\d+$/.test(r[i]);
        if ((gx && !isnum)){
            return [i+1,px]
        }
        if((gx && i === 0)){
            return [i,px]
        }

        if (!gx && isnum){
            px = i
            gx = true
        }

    }
    return [0,0]
}

const question_txt_style = {
    width: '46vw', height: '3vw',
    fontFamily: 'verdana',fontSize:'1.2vw',textAlign:'center',
    alignSelf:'center',justifyContent:'center',
    color: '#000000', display: 'flex', alignItems: 'center',
}
const popup_btn_style = {
    backgroundColor:'#56CFE1',color:'white',width:'15vw',height:'2.5vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    fontFamily: 'verdana',fontSize:'1.2vw',borderRadius:'1.5vw'
}
const cancelIconStyle = {
    width:'1vw',height:'1vw',marginTop:'1vw',cursor:'pointer'
}
const input_div = {
    resize:'horizontal',overflow:'hidden', height:'2vw',minHeight:'2vw',borderRadius:'1.5vw',
    paddingLeft:'0.4vw',cursor:'ew-resize',
    width:'23vw',maxWidth:'30vw',minWidth:'1vw',outline:'none',border:'0.06vw solid black',marginTop:'0.75vw'
}
const txt_div = {
    display:'flex',marginTop:'0.75vw',marginLeft:'2vw',justifyContent:'left',
    height:'1.82vw',paddingTop:'0.3vw',width:'16vw',cursor:'pointer'
}
const root_div = {
    height:'100%',display:'flex',flexDirection:'column',
    borderRadius: 0,backgroundColor:'white',
    width:'50vw'
}
const ant_body= {
    width: '100%', height: '37vw',resize: 'horizontal',overflow: 'auto',
    borderRadius: 0, border: '0.2vw solid #FACE4E', maxWidth:'50vw',
    minWidth:'1vw'
}
const col2box_Style = {
    width:'6vw',height: '2vw',backgroundColor:'#FFFFFF',marginLeft:'1vw',
    display:'flex',flexDirection:'row',border:'0.06vw solid black',borderRadius:'1.5vw'
}
const col2_div = {
    height:'20.75vw',width:'100%',overflow:'auto',display:'flex',flexDirection:'row',paddingTop: '1.25vw'
}
const col2 = {
    display:'flex',flexDirection:'column',width:'auto',height:'auto'
}
const override = css`
  display: block;
  margin: 25vw 18vw 20vw 20vw;
  border-color: red;
  position:absolute;
`;

export default QuestionPrint;
