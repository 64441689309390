import React,{useState} from 'react';
import {Modal} from "antd";
import DuplicateField from "./DuplicateField";

const CopyPastePopup = (props) => {
    let [pasteName,setPasteName] = useState('')
    let [duplicate,setDuplicate] = useState(false)
     return (
        <Modal
            visible={props.status} width={'30vw'}
            closable={false}

            destroyOnClose={true}
            style={{

                height: '21.23vw', padding: 0, borderRadius: 0, top: '15vw',left:'35vw',
                display: 'flex', flexDirection: 'column',position:'absolute',zIndex:4
            }} footer={null}
            bodyStyle={{
                width: '29.8vw', height: '21.23vw',
                borderRadius: 0, border: '0.2vw solid #FACE4E',
            }}>
            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',
                borderRadius: 0,backgroundColor:'white'}}>

                <div style={{
                    width: '30vw', height: '3vw', backgroundColor: '#FACE4E', fontFamily: 'verdana',fontSize:'1.2vw',
                    color: '#000000', display: 'flex', alignItems: 'center', textAlign:'center',alignSelf:'center',
                    justifyContent:'center',marginTop:'-0.2vw',padding:0
                }}>
                    {props.langData.paste}
                </div>
                <DuplicateField langDatax={props.langData} status={duplicate} changeStatus={()=>setDuplicate(false)}/>

                <div style={{
                    color: 'black',
                    height:'2vw',
                    paddingTop:'4vw',
                    textAlign:'center',
                    fontFamily: 'verdana',fontSize:'1.2vw',
                }}>{props.langData.uniquePaste}
                </div>
                <input value={pasteName} onChange={(e)=>{
                    setPasteName(e.currentTarget.value)
                }} style={{borderRadius:'1.5vw',backgroundColor:'white',border:'0.01vw solid grey',
                width:'25vw',height:'2.5vw',marginLeft:'2.5vw',outline:'none',marginTop:'1vw',paddingLeft:'1vw'}}/>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    paddingTop:'3vw',
                    height:'4vw',
                    width: '30vw',
                }}>
                    <div style={{...popup_btn_style,marginLeft:'3.33vw'}} onClick={props.changeStatus} >{props.langData.cancel}</div>
                    <div style={{...popup_btn_style,marginLeft:'3.33vw'}}
                       onClick={pasteName.length > 0 ? () => {
                              if (props.checkPaste(pasteName) === false){
                                  props.onPaste(pasteName)
                                  setPasteName('')
                              }else {
                                  setDuplicate(true)
                                  setPasteName('')
                              }

                           } : null
                       }  >{props.langData.ok}
                    </div>

                </div>
            </div>
        </Modal>
    )
}
const popup_btn_style = {
    backgroundColor:'#56CFE1',color:'white',width:'10vw',height:'2.5vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    fontFamily: 'verdana',fontSize:'1.2vw',borderRadius:'1.5vw'
}
export default CopyPastePopup;
