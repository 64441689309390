import React from 'react';
import {Modal} from "antd";

const CsvDataReset = (props) => {
    return (
        <Modal
            visible={props.status} width={'33.2vw'}
            closable={false}

            destroyOnClose={true}
            style={{
                height: '11vw', padding: 0, borderRadius: 0, top: '15vw',left:'33vw',
                display: 'flex', flexDirection: 'column',position:'absolute',zIndex:4
            }} footer={null}
            bodyStyle={{
                width: '100%', height: '11w',
                borderRadius: 0, border: '0.2vw solid #FACE4E',
            }}>
            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',
                borderRadius: 0,backgroundColor:'white'}}>

                <div style={{
                    width: '33.2vw', height: '3vw', backgroundColor: '#FACE4E'
                    , fontFamily: 'verdana',fontSize:'1.2vw',textAlign:'center',
                    alignSelf:'center',justifyContent:'center',
                    color: '#000000', display: 'flex', alignItems: 'center',
                }}>
                    Remove Data File?
                </div>

                <div style={{
                    color: 'black',
                    backgroundColor: 'white',
                    paddingTop: '1vw',textAlign:'center',
                    height:'5vw',
                    fontFamily: 'verdana',fontSize:'1.2vw',
                }}>If you remove your data file you will remove all data links to text fields. Do you still want to remove the data file?
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    paddingTop:'1.5vw',
                    height:'3vw',
                    width: '33.2vw',
                }}>
                    <div style={{...popup_btn_style,marginLeft:'5.6vw'}} onClick={props.onYes} >Yes</div>
                    <div style={{...popup_btn_style,marginRight:'5.6vw',marginLeft:'5.6vw'}} onClick={props.changeStatus}>No</div>

                </div>
            </div>
        </Modal>
    )
}
const popup_btn_style = {
    backgroundColor:'#56CFE1',color:'white',width:'8.5vw',height:'2vw',
    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer',
    fontFamily: 'verdana',fontSize:'1.2vw',borderRadius:'1.5vw'
}
export default CsvDataReset;
