import React, { Component } from 'react';
import { render } from 'react-dom';
import { Stage, Layer,Rect ,Text } from 'react-konva';
import Konva from 'konva';
import {connect} from "react-redux";
import {WIDTH,LABEL_X,LABEL_Y,LABEL_WIDTH,LABEL_HEIGHT} from "../../actions";

class ColoredRect extends React.Component {
    render() {

        return (
            <Rect
                width={this.props.width}
                scaleX={1} scaleY={1}
                _useStrictMode


                strokeWidth={1} // border width
                stroke="black" // border color

                height={this.props.height}
            />
        );
    }
}

class DrawLabel extends Component {
    constructor(props){
        super(props);
        window.addEventListener("resize", this.updated);
        this.state = {
            width: window.innerWidth,
            height: window.outerHeight,
        };


    }
    updated = () => {
        this.setState({
            width: window.innerWidth,
            height: window.innerWidth/2,

        });
    };
    labeRect = () => {
        const width_num = Number(this.props.width);
        const height_num = Number(this.props.height);

        if (width_num > height_num){
            const lbx_ratio = width_num/ height_num;
            const stg_yh = (this.props.layerHeight - (this.state.width * 0.4 /lbx_ratio))/2 ;
            let stg_width = this.state.width * 0.5 * this.props.scale_factor;
            let stg_height = stg_yh * 2+ (this.state.width * 0.4 /lbx_ratio) * this.props.scale_factor;
            let stg_x = this.state.width * 0.05 * this.props.scale_factor;
            let stg_y = this.state.width * 0.05 * this.props.scale_factor;

            if (this.props.scale_factor < 1){
                stg_width = this.state.width * 0.5;
                stg_height = stg_yh * 2+ (this.state.width * 0.4 /lbx_ratio)
                stg_x = ((this.state.width * 0.5 - (this.state.width * 0.5 * this.props.scale_factor))/2 )
            }
            let clrd_w = this.state.width * 0.4
            let clrd_h = this.state.width * 0.4 /lbx_ratio

            let rtz = Math.round(clrd_h/clrd_w)

            if (rtz === 1){
                clrd_w = this.state.width * 0.3
                clrd_h = this.state.width * 0.3 /lbx_ratio
                stg_x = this.state.width * 0.1 * this.props.scale_factor;
                if (this.props.scale_factor < 1){
                    stg_x = ((this.state.width * 0.5 - (this.state.width * 0.3 * this.props.scale_factor))/2 )
                }


                }
            this.props.dispatch(LABEL_X(stg_x));
            this.props.dispatch(LABEL_Y(stg_y))
            this.props.dispatch(LABEL_WIDTH(clrd_w));
            this.props.dispatch(LABEL_HEIGHT(clrd_h))




            return(
                <div style={{overflow:'hidden',width: (stg_width), height: (stg_height), zIndex:1}}>

                <Stage x={stg_x} y={stg_y}  width={stg_width } height={stg_height }  >

                <Layer  scaleX={this.props.scale_factor} scaleY={this.props.scale_factor} >

                    <ColoredRect  width={clrd_w} height={clrd_h} />
                </Layer>
            </Stage>
                </div>

            )
        }
        if (width_num === height_num) {

            const stg_yh = (this.props.layerHeight - (this.state.width * 0.3))/2 ;
            let stg_width = this.state.width * 0.5 * this.props.scale_factor;
            let stg_x = this.state.width * 0.1 * this.props.scale_factor
            let stg_y = this.state.width * 0.05 * this.props.scale_factor;

            let stg_height = stg_yh * 2+ (this.state.width * 0.3) * this.props.scale_factor;
            if (this.props.scale_factor < 1){
                stg_width = this.state.width * 0.5;
                stg_height = stg_yh * 2+ (this.state.width * 0.3);
                stg_x = ((this.state.width * 0.5 - (this.state.width * 0.3 * this.props.scale_factor))/2 )
            }
            this.props.dispatch(LABEL_X(stg_x));
            this.props.dispatch(LABEL_Y(stg_y))
            this.props.dispatch(LABEL_WIDTH(this.state.width * 0.3));
            this.props.dispatch(LABEL_HEIGHT(this.state.width * 0.3))
            return (
                <div style={{overflow:'hidden',width: stg_width, height:stg_height}}>

                <Stage   x={stg_x} y={stg_y} width={stg_width}
                       height={stg_height} >

                    <Layer scaleX={this.props.scale_factor} scaleY={this.props.scale_factor}>

                        <ColoredRect width={this.state.width * 0.3} height={this.state.width * 0.3}/>
                    </Layer>
                </Stage>
                </div>
            )
        }
            if (width_num < height_num) {
                const lbx_ratio = height_num/ width_num;
                let lb_height = this.state.width * 0.3;
                const lb_width = this.state.width * 0.3 / lbx_ratio;
                if (lb_height >= this.props.layerHeight){
                    lb_height = lb_height * 0.9
                }
                let stg_x = ((this.state.width  *0.5)-(lb_width))/2;
                let stg_y = this.state.width * 0.05 * this.props.scale_factor;

                const stg_yh = (this.props.layerHeight - (lb_height))/2;
                let stg_width = this.state.width * 0.5 * this.props.scale_factor;
                let stg_height = this.props.layerHeight * this.props.scale_factor;
                if (this.props.scale_factor < 1){
                    stg_width = this.state.width * 0.5;
                    stg_height = this.props.layerHeight;
                    stg_x = ((this.state.width * 0.5 - lb_width)/2 )
                }
                this.props.dispatch(LABEL_X(stg_x));
                this.props.dispatch(LABEL_Y(stg_y))
                this.props.dispatch(LABEL_WIDTH(lb_width));
                this.props.dispatch(LABEL_HEIGHT(lb_height))

                return (
                    <div style={{overflow:'hidden',width: stg_width, height: stg_height}}>

                    <Stage  scaleX={this.props.scale_factor} scaleY={this.props.scale_factor} x={stg_x} y={stg_y}  width={stg_width} height={stg_height}   >

                        <Layer>

                            <ColoredRect width={lb_width} height={lb_height} />
                        </Layer>
                    </Stage>
                    </div>
                )

        }
    }

    render() {



        // Stage is a div container
        // Layer is actual canvas element (so you may have several canvases in the stage)
        // And then we have canvas shapes inside the Layer
        return (
            <div>


                {
                    this.labeRect()
                }
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        scale_factor: state.counter.scale_factor

    };
}


export default connect(mapStateToProps)(DrawLabel);
