import React, {Component} from 'react'
import logo from '../assets/componentImages/logo.png';
import cloutLite from '../assets/images/cloutlite.png';
import {connect} from "react-redux";

class header extends Component {

    constructor(props) {
        super(props);
        this.state = {dimensions: {}};
        this.onImgLoad = this.onImgLoad.bind(this);
    }
    onImgLoad({target:image}) {
        this.setState({dimensions:{height:image.offsetHeight,
                width:image.offsetWidth}});


    }

    render() {
        let Height = this.props.win_height*0.12;
        let Width = Height/1.5*3.333;


        let t = 5.5;

        // if (this.props.win_width*0.25 < Width*2){
        //     Width = this.props.win_width*0.25/2;
        //     marginAround = (this.props.win_width*0.25-Width)/2;
        //     Height = Width*1.5/3.333;
        //     marginTopValue = (this.props.win_height*0.25-Height)/4.5;
        // }
        // if (this.props.win_height*0.12 < Height*2){
        //     Height = this.props.win_height*0.12/2;
        //     Width = Height/1.5*3.333;
        //
        //     marginAround = (this.props.win_width*0.25-Width)/2;
        //     marginTopValue = (this.props.win_height*0.25-Height)/4.5;
        // }

        return(
            <React.Fragment>
                <div className='row' style={{
                   width:'100vw',  height: '5.5vw',

                    display: 'flex',
                }}>
                    <div style={{ width:'25vw', height:'100%',
                          backgroundColor: '#FFFFFF',zIndex:3,display:'flex',
                        justifyContent:'center',alignItems:'center'

                    }}>

                        <img
                            src={logo}
                            alt="logo"
                            width = '75%'
                            height = 'null'
                            style={{ margin: 'auto',
                                textAlign: 'center',cursor:'pointer',
                                }}
                            onClick={()=>window.open('https://www.zetags.com/', '_blank')}
                            onLoad={this.onImgLoad.bind(this)}
                                                    />

                    </div>
                    <div style={{width:'50vw',height:'100%',
                        padding: 0  ,  backgroundColor: '#FACE4E',zIndex:3}}>

                    </div>
                    <div style={{ width:'25vw', height:'100%',
                        backgroundColor: '#FFFFFF',zIndex:3,display:'flex',
                        justifyContent:'center',alignItems:'center'

                    }}>

                        <img
                            src={cloutLite}
                            alt="logo"
                            width = '100%'
                            height = '100%'
                            style={{
                                textAlign: 'center',cursor:'pointer',
                            }}
                            // onLoad={this.onImgLoad.bind(this)}
                        />

                    </div>
                </div>

            </React.Fragment>


        )
    }



}
const textStyle = {
    color: 'black',
    fontFamily: 'verdana',
    textAlign: "center",
    fontWeight: 'normal',
    verticalAlign: 'center',
    fontSize: '1.2vw'
};

function mapStateToProps(state) {
    return {
        win_width: state.counter.Window_Width,
        win_height: state.counter.Window_Height

    };
}

export default connect(mapStateToProps) (header)

