import React, {Component,useState} from 'react'
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import ColorPicker from 'rc-color-picker';
import './colorPicker.css';
import './toolTip_Style.css'

const bold_On = require('../../assets/Tab_Images/Text/btn_Bold_Enabled.png');
const bold_Off = require('../../assets/Tab_Images/Text/btn_Bold_Disabled.png');

const italics_On = require('../../assets/Tab_Images/Text/btn_Italic_Enabled.png');
const italics_Off = require('../../assets/Tab_Images/Text/btn_Italic_Disabled.png');

const underline_On = require('../../assets/Tab_Images/Text/btn_Underline_Enable.png');
const underline_Off = require('../../assets/Tab_Images/Text/btn_Underline_Disabled.png');

const strike_On = require('../../assets/Tab_Images/Text/btn_Strike_Enabled.png');
const strike_Off = require('../../assets/Tab_Images/Text/btn_Strike_Disabled.png');

const reverse_On = require('../../assets/Tab_Images/Text/btn_Reverse_Enabled.png');
const reverse_Off = require('../../assets/Tab_Images/Text/btn_Reverse_Disabled.png');

const arrow_Up = require('../../assets/Tab_Images/Text/arrow_up.png')
const arrow_Down = require('../../assets/Tab_Images/Text/arrow_down.png')

const left_AlignOff = require('../../assets/Tab_Images/Text/btn_Text_Left_Disabled.png');
const left_AlignOn = require('../../assets/Tab_Images/Text/btn_Text_Left_Enabled.png');

const center_AlignOff = require('../../assets/Tab_Images/Text/btn_Text_Centre_Disabled.png');
const center_AlignOn = require('../../assets/Tab_Images/Text/btn_Text_Centre_Enabled.png');

const right_AlignOff = require('../../assets/Tab_Images/Text/btn_Text_Right_Disabled.png');
const right_AlignOn = require('../../assets/Tab_Images/Text/btn_Text_Right_Enabled.png');

const multiOff = require('../../assets/Tab_Images/Text/btn_Text_Paragraph_Disabled.png');
const multiOn = require('../../assets/Tab_Images/Text/btn_Text_Paragraph_Enabled.png');

const para_Line = require('../../assets/Tab_Images/Text/btn_Text_Line_Space_Enabled.png');
const para_Line_off = require('../../assets/Tab_Images/Text/btn_Text_Line_Space_Disabled.png');

const para_Width = require('../../assets/Tab_Images/Text/btn_Text_Line_Width_Enabled.png');
const para_Width_off = require('../../assets/Tab_Images/Text/btn_Text_Line_Width_Disabled.png');

const btn_X_e = require('../../assets/Tab_Images/Text/lbl_X-dim-Enabled.png');
const btn_X_d = require('../../assets/Tab_Images/Text/lbl_X-dim-Disabled.png');

const btn_Y_e = require('../../assets/Tab_Images/Text/lbl_Y-dim-Enabled.png');
const btn_Y_d = require('../../assets/Tab_Images/Text/lbl_Y-dim-Disabled.png');

const left_On = require('../../assets/Tab_Images/Text/btn_Move_Left_Enable.png');
const left_Off = require('../../assets/Tab_Images/Text/btn_Move_Left_Disabled.png');

const right_On = require('../../assets/Tab_Images/Text/btn_Move_Right_Enabled.png');
const right_Off = require('../../assets/Tab_Images/Text/btn_Move_Right_Disabled.png');

const up_On = require('../../assets/Tab_Images/Text/btn_Move_Up_Enabled.png');
const up_Off = require('../../assets/Tab_Images/Text/btn_Move_Up_Disabled.png');

const down_On = require('../../assets/Tab_Images/Text/btn_Move_Down_Enabled.png');
const down_Off = require('../../assets/Tab_Images/Text/btn_Move_Down_Disabled.png');

const rotateLeft_e = require('../../assets/Tab_Images/Text/btn_Rotate_Left_Enabled.png');
const rotateLeft_d = require('../../assets/Tab_Images/Text/btn_Rotate_Left_Disabled.png');

const rotateRight_e = require('../../assets/Tab_Images/Text/btn_Rotate_Right_Enabled.png');
const rotateRight_d = require('../../assets/Tab_Images/Text/btn_Rotate_Right_Disabled.png');

const forward_e = require('../../assets/Tab_Images/Text/btn_Front_Enabled.png');
const forward_d = require('../../assets/Tab_Images/Text/btn_Front_Disabled.png');

const backward_e = require('../../assets/Tab_Images/Text/btn_Back_Enabled.png');
const backward_d = require('../../assets/Tab_Images/Text/btn_Back_Disabled.png');

const transparent_e = require('../../assets/Tab_Images/Text/btn_TextTransparent_Enabled-8.png')
const transparent_d = require('../../assets/Tab_Images/Text/btn_TextTransparent_Disabled-8.png')


const text_Input = require('../../assets/componentImages/buttonTextInput.jpg');
const prefix = require('../../assets/componentImages/buttonPrefix.jpg');
const tick = require('../../assets/Tab_Images/Text/tick.png');
const icon_filler = require('../../assets/Tab_Images/Text/Icon_Filler.png')


const options = [
    {
        label: "Actor",
        value: 1,
        data: false,
    },
    {
        label: "Adamina",
        value: 2,
        data: false,
    },
    {
        label: "Akronim",
        value: 3,
        data: false,
    },
    {
        label: "Aladdin",
        value: 4,
        data: false,
    },
    {
        label: "Aldrich",
        value: 5,
        data: false,
    },
    {
        label: "Allura",
        value: 6,
        data: false,
    },
    {
        label: "Almendra",
        value: 7,
        data: true,
    },
    {
        label: "Amiri",
        value: 8,
        data: true,
    },
    {
        label: "Andika",
        value: 9,
        data: false,
    },
    {
        label: "Arial",
        value: 10,
        data: true,
    },
    {
        label: "Arimo",
        value: 11,
        data: true,
    },
    {
        label: "Arizonia",
        value: 12,
        data: false,
    },
    {
        label: "Baloo",
        value: 13,
        data: false,
    },
    {
        label: "Basic",
        value: 14,
        data: false,
    },
    {
        label: "Baumans",
        value: 15,
        data: false,
    },
    {
        label: "Bayon",
        value: 16,
        data: false,
    },
    {
        label: "Bmhanna",
        value: 17,
        data: false,
    },
    {
        label: "Bonbon",
        value: 18,
        data: false,
    },
    {
        label: "Cambo",
        value: 19,
        data: false,
    },
    {
        label: "Candal",
        value: 20,
        data: false,
    },
    {
        label: "Capriola",
        value: 21,
        data: false,
    },
    {
        label: "Caudex",
        value: 22,
        data: true,
    },
    {
        label: "Caviar",
        value: 23,
        data: true,
    },
    {
        label: "Dekko",
        value: 24,
        data: false,
    },
    {
        label: "Delius",
        value: 25,
        data: false,
    },
    {
        label: "Dorsa",
        value: 26,
        data: false,
    },
    {
        label: "Electrolize",
        value: 27,
        data: false,
    },
    {
        label: "Faunaone",
        value: 28,
        data: false,
    },
    {
        label: "Gabriela",
        value: 29,
        data: false,
    },
    {
        label: "Gafata",
        value: 30,
        data: false,
    },
    {
        label: "Geostar",
        value: 31,
        data: false,
    },
    {
        label: "Habibi",
        value: 32,
        data: false,
    },
    {
        label: "Iceberg",
        value: 33,
        data: false,
    },
    {
        label: "Lato",
        value: 34,
        data: true,
    },
    {
        label: "PtSans",
        value: 35,
        data: true,
    },
];
class TextLayer extends Component {
    constructor(props) {
        super(props);

        // This line is important!
    }
    state = {
        fixedData: false,
        question: false,
        date: false,
        increment: false,
        lock: false,
        no_print: false,
        bold: true,
        italics: false,
        strike:false,
        underline: false,
        reverse: false,
        leftAlign: false,
        centerAlign: false,
        rightAlign: false,
        multi: false,
        clock: false,
        anti_clock: false,
        forward: false,
        backward: false,
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        },
        textSelected: false


    };

    render() {

        let selectedTextBoolean = false;
        if (this.props.clickedText) {
           selectedTextBoolean = true;
        }

        return(

            <div style={{ backgroundColor: '#EEEEEE', padding: 0,
                width : "21vw",zIndex:3,overflow:'auto',minHeight:this.props.heightLayer}}>

                <ReactTooltip place="bottom" type="dark" effect="float"/>
                <div data-place={'right'} data-offset="{'left': 0,'right':0}"  data-tip={this.props.langData.name} style={{marginLeft:'2.5vw', marginRight:'2.5vw', width:"15.6vw",
                    marginTop: '1.7vw',height:'2.66vw'}}>
                <input
                    style={{backgroundColor:'white',width:'100%',height:'100%',
                        textAlign:'center',justifyContent:'center', alignItems:'center',fontFamily: 'verdana',
                        fontSize:'1vw',border:'none'}}
                       disabled={!selectedTextBoolean}  type="text" align={'center'} value={this.props.pinTextStatus}
                        onChange={this.props.changePinText}
                        />
                </div>

                <div style={container_Style}>
                <div style={row1}>
                    <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.style}  style={{width:'12vw'}}>
                    <Select styles={customStyles}  onChange={value => this.props.fontChange(value)} value={{ label: this.props.fontFamilyStatus, value: this.props.fontFamilyStatusIndex }}
                            defaultValue={{ label: this.props.fontFamilyStatus, value: this.props.fontFamilyStatusIndex }}
                             options={options}   />
                    </div>
                    <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.size}  style={{width:'3.8vw',marginLeft:'0.2vw',height:'2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                        <input onChange={this.props.fontSizeChange}  value={this.props.fontSizeValue}  style={{width:'2.5vw',outline:'none',border:'none',
                            marginLeft:'0.1vw',height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                        <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                            <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={() =>this.props.numberInputChange(Number(this.props.fontSizeValue)+0.5)}/>

                            <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={() =>this.props.numberInputChange(Number(this.props.fontSizeValue)-0.5)}/>


                        </div>
                    </div>
                </div>

                <div style={row2_L2}>
                    <div style={btn_Style_L2} data-tip={this.props.langData.bold} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='bold'  src={this.props.boldStatus ? bold_On :bold_Off} onClick={this.props.onBold} />
                    </div>
                    <div style={btn_Style_L2} data-tip={this.props.langData.italic} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='italics'  src={this.props.italicStatus? italics_On :italics_Off} onClick={this.props.onItalic} />
                    </div>
                    <div style={btn_Style_L2} data-tip={this.props.langData.strike} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='strike'  src={this.props.lineThroughStatus ? strike_On :strike_Off} onClick={this.props.onlineThrough} />
                    </div>
                    <div style={btn_Style_L2} data-tip={this.props.langData.underline} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='underline'  src={this.props.underlineStatus ? underline_On :underline_Off} onClick={this.props.onUnderline} />
                    </div>
                    <div style={btn_Style_L2} data-tip={this.props.langData.invert} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='reverse'  src={this.props.flipTextStatus ? reverse_On :reverse_Off} onClick={this.props.onFlip} />
                    </div>
                    <div style={{...btn_Style_L2,backgroundColor:'#FFFFFF'}} data-tip={this.props.langData.transparent} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={{...img_row1_C1_L2,width:'100%',height:'100%'}} alt='transparent'  src={this.props.statusTransparent ? transparent_e :transparent_d}
                             onClick={this.props.changeTransparent} />
                    </div>

                </div>
                <div style={row2_L2}>

                    <div style={btn_Style_L2} data-tip={this.props.langData.left} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='left'  src={this.props.leftAlignStatus ? left_AlignOn : left_AlignOff} onClick={this.props.onLeftTextAlign} />
                    </div>
                    <div style={btn_Style_L2} data-tip={this.props.langData.center} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='center'  src={this.props.centerAlignStatus ? center_AlignOn :center_AlignOff} onClick={this.props.onCenterTextAlign} />
                    </div>
                    <div style={btn_Style_L2} data-tip={this.props.langData.right} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='right'  src={this.props.rightAlignStatus ? right_AlignOn :right_AlignOff} onClick={this.props.onRightTextAlign} />
                    </div>
                    <div style={btn_Style_L2} data-tip={this.props.langData.multiline} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='multi' onClick={this.props.onMultiline}  src={this.props.multilineStatus  ? multiOn :multiOff}
                       />
                    </div>
                    <div data-tip={this.props.langData.tColor} data-place={'right'} data-offset="{'left': 0,'right':0}" >
                        <ColorPicker className={'black'}  color={this.props.textColorStatus}  onChange={this.props.onTextColor} placement="bottomRight" />
                    </div>
                    <div data-tip={this.props.langData.bColor} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <ColorPicker  className={'white'}  onChange={this.props.onBackgroundColor} placement="bottomRight"
                                      color={this.props.backgroundColorStatus === 'transparent' ? '#FFFFFF':this.props.backgroundColorStatus} />

                    </div>

                </div>
                <div style={row2_L2}>
                    <div style={{...btn_Style_L2}}>
                        <img style={img_row1_C1_L2} onClick={this.props.onParaWidth} alt='multi'  src={this.props.multilineStatus ? para_Width:para_Width_off} />
                    </div>
                    <div data-tip={this.props.langData.maxW} data-place={'right'} data-offset="{'left': 0,'right':0}" style={{width:'5.32vw',height: '2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                        <input onChange={this.props.changeMulti}  disabled={!this.props.multilineStatus}  value={this.props.multilineStatus ? this.props.multilineWidthStatus: ''}   style={{width:'4vw',outline:'none',border:'none',marginLeft:'0.1vw',height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                        <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                            <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={()=>this.props.numberChangeMultiWidth(Math.round(Number(this.props.multilineWidthStatus))+1)} />

                            <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={()=>this.props.numberChangeMultiWidth(Math.round(Number(this.props.multilineWidthStatus))-1)} />

                        </div>
                    </div>
                    <div style={{...btn_Style_L2}}>
                        <img style={img_row1_C1_L2} onClick={this.props.onParaHeight} alt='multi'  src={this.props.multilineStatus ? para_Line:para_Line_off} />
                    </div>
                    <div data-tip={this.props.langData.vLine} data-place={'right'} data-offset="{'left': 0,'right':0}" style={{width:'5.32vw',height: '2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                        <input onChange={this.props.changeMultiLineHeight} disabled={!this.props.multilineStatus}   value={this.props.multilineStatus ? this.props.multilineHeightStatus: ''}  style={{width:'4vw',outline:'none',border:'none',marginLeft:'0.1vw',height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                        <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                            <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={Number(this.props.multilineHeightStatus) === 0 ? ()=>this.props.numberChangeMultiHeight(0.1): ()=>this.props.numberChangeMultiHeight((Math.round(this.props.multilineHeightStatus * 10) / 10)+0.1)} />

                            <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={Number(this.props.multilineHeightStatus) === 0 ? null : this.props.multilineHeightStatus === 0.1 ? ()=>this.props.numberChangeMultiHeight(0) : ()=>this.props.numberChangeMultiHeight((Math.round(this.props.multilineHeightStatus * 10) / 10)-0.1)}/>

                        </div>
                    </div>
                    {/*<input disabled={!selectedTextBoolean} style={input_style_para} type="text" align={'center'}  defaultValue={''} />*/}
                </div>
                <div style={row2_L2}>
                    <div style={btn_Style_L2} >
                        <img style={img_row1_C1_L2} alt='x' src={selectedTextBoolean ? btn_X_e: btn_X_d} />
                    </div>
                    <div data-tip={this.props.langData.pLeft} data-place={'right'} data-offset="{'left': 0,'right':0}">
                    <input  style={input_style_para} disabled={!selectedTextBoolean}  type="text" align={'center'}
                           value={this.props.textXposStatus === null ? '': this.props.textXposStatus} onChange={this.props.textXPosChange}  />
                    </div>
                    <div style={btn_Style_L2} >
                        <img style={img_row1_C1_L2} alt='y'  src={selectedTextBoolean ? btn_Y_e: btn_Y_d} />

                    </div>
                    <div data-tip={this.props.langData.pTop} data-place={'right'} data-offset="{'left': 0,'right':0}">
                    <input  style={input_style_para} disabled={!selectedTextBoolean} onChange={this.props.textYPosChange}
                            value={this.props.textYposStatus === null ? '': this.props.textYposStatus}  type="text" align={'center'}  />
                    </div>




                </div>

                <div style={row2_L2}>
                    <div onMouseUp={this.props.onRightMouseUp} onMouseDown={this.props.onRightMouseDown}   style={btn_Style_L2} data-tip={this.props.langData.mR} data-place={'right'} data-offset="{'left': 0,'right':0}" >
                        <img style={img_row1_C1_L2} alt='right' src={selectedTextBoolean ? right_On: right_Off} />
                    </div>
                    <div onMouseUp={this.props.onUpMouseUp} onMouseDown={this.props.onUpMouseDown} style={btn_Style_L2} data-tip={this.props.langData.mU} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='up'   src={selectedTextBoolean ? up_On: up_Off} />
                    </div>
                    <div onMouseUp={this.props.onDownMouseUp} onMouseDown={this.props.onDownMouseDown}   style={btn_Style_L2} data-tip={this.props.langData.mD} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='down'  src={selectedTextBoolean ? down_On: down_Off} />
                    </div>
                    <div onMouseUp={this.props.onLeftMouseUp} onMouseDown={this.props.onLeftMouseDown}  style={btn_Style_L2} data-tip={this.props.langData.mL} data-place={'right'} data-offset="{'left': 0,'right':0}">
                        <img style={img_row1_C1_L2} alt='left' src={selectedTextBoolean ? left_On: left_Off} />
                    </div>
                    <div style={{...btn_Style_L2,backgroundColor:'#ececec'}}>
                        <img style={img_row1_C1_L2} alt='reverse'  src={icon_filler}  />
                    </div>
                    <div style={{...btn_Style_L2,backgroundColor:'#ececec'}}>
                        <img style={img_row1_C1_L2} alt='reverse'  src={icon_filler}  />
                    </div>



                </div>

                    <div style={row2_L2}>
                        <div onClick={this.props.clockAntiTransform} style={btn_Style_L2} data-tip={this.props.langData.cclock}  data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1_L2} alt='clock'  src={selectedTextBoolean ? rotateLeft_e: rotateLeft_d} />
                        </div>
                        <div onClick={this.props.clockTransform} style={btn_Style_L2} data-tip={this.props.langData.clock} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1_L2} alt='anticlock'  src={selectedTextBoolean ? rotateRight_e: rotateRight_d} />

                        </div>
                        <div data-tip={this.props.langData.degree} data-place={'right'} data-offset="{'left': 0,'right':0}" style={{width:'5.32vw',height: '2.66vw',backgroundColor:'#FFFFFF',display:'flex',flexDirection:'row'}}>
                            <input onChange={this.props.changeRotation}  disabled={!selectedTextBoolean}  value={selectedTextBoolean ? this.props.transformRotateValue: ''}   style={{width:'4vw',outline:'none',border:'none',marginLeft:'0.1vw',height:'90%',fontSize:'0.8vw', fontFamily:'verdana',}}/>
                            <div style={{display:'flex',flexDirection:'column',width:'0.7vw',height:'2.66vw',justifyContent:'space-around'}}>
                                <img alt={'arrowup'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Up} onClick={selectedTextBoolean ? ()=>this.props.numberChangeRotation(Math.round(Number(this.props.transformRotateValue))+1): null} />

                                <img alt={'arrowdown'} style={{width:'0.7vw',height:'auto',cursor:'pointer'}} src={arrow_Down} onClick={selectedTextBoolean ? ()=>this.props.numberChangeRotation(Math.round(Number(this.props.transformRotateValue))-1):null} />

                            </div>
                        </div>
                        <div onClick={selectedTextBoolean ? this.props.bringForward: null} style={btn_Style_L2} data-tip={this.props.langData.forward} data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1_L2} alt='forward'  src={selectedTextBoolean ? forward_e: forward_d} />

                        </div>
                        <div onClick={selectedTextBoolean ? this.props.bringBackward: null} style={btn_Style_L2} data-tip={this.props.langData.backward} data-place={'right'} data-offset="{'left': 0,'right':0}"data-place={'right'} data-offset="{'left': 0,'right':0}">
                            <img style={img_row1_C1_L2} alt='backward'  src={selectedTextBoolean ? backward_e: backward_d} />
                        </div>

                    </div>
                </div>


                <div style={{...row2_L2,marginLeft:'2.5vw',marginRight:'2.5vw' }}>
                            <div style={{display:'flex',flexDirection:'row',flex:1,alignItems:'center'}}>
                                <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.lock}
                                     style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.3vw',height:'2.3vw',
                                    display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={this.props.onLock}>
                                    {
                                        this.props.textLockedStatus ?
                                            <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                            null

                                    }
                                </div>
                            <div style={text_Style}>{this.props.langData.lockT}</div>
                            </div>

                            <div style={{display:'flex',flexDirection:'row',flex:1,alignItems:'center'}}>
                                <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.props.langData.noPrint}
                                     style={{...btn_Style_L2,backgroundColor:'#FFFFFF',border:'0.06vw solid black',width:'2.3vw',height:'2.3vw',
                                display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={this.props.onNoPrint}>
                                    {
                                        this.props.textNoPrintStatus ?
                                            <img style={{...img_row1_C1_L2,width:'70%'}} alt='new button'  src={tick}  />:
                                            null

                                    }
                                </div>
                            <div style={text_Style}>{this.props.langData.noPrintT}</div>


                    </div>
                </div>
                <div style={{...row5,cursor:'pointer'}} onClick={this.props.addtoArray}>
                    <div style={{color:'white',fontSize: '1vw',fontFamily: 'verdana',cursor:'pointer'}}>{this.props.langData.newText}</div>
                </div>



                </div>

        )

    }
}
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 0,
        border:'none',
        borderRadius:0,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
        width:'2.26ex',
        strokeWidth:1,

    }),
    clearIndicator: base => ({
        ...base,
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        fontSize:'0.8vw',
        fontFamily:'verdana',
        paddingLeft:'0.5vw'
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        height:'2.66vw',
        border:'none'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
        height:'2.66vw'
    }),
    indicatorsContainer: base => ({
        ...base,
        padding: 0,
        height:'2.66vw',
    }),
    menuList: base => ({
        ...base,
        fontSize:'0.8vw',
        fontFamily:'verdana',
    }),

};
const container_Style ={
    border: 'none',marginLeft:'2.5vw', marginRight:'2.5vw',
    width:'16vw',

};

const row1 = {
    marginLeft:'0.1vw', height: '2.66vw',marginRight:'0.1vw', marginTop:'0.9vw',border:'none',
    display:'flex',flexDirection:'row'

};



const row2_L2 = {
    display:'flex',flexDirection:'row',marginLeft:'0.1vw', height: '2.66vw',marginTop:'0.6vw',marginRight:'0.1vw'

};

const row5 = {
    flex:1, flexDirection:'row',display:'flex',width:'15.88vw',justifyContent:'center',alignItems:'center',
    marginLeft:'2.5vw',marginRight:'2.5vw', height: '2.66vw',backgroundColor:'#F58C32', marginTop:'0.6vw',border: 'none',

};





const btn_Style_L2={
    width: '2.66vw',height:'2.66vw', display:'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#cccccc'
};

const input_style_para = {
    width:'5.32vw',height: '2.66vw',textAlign:'center', display:'flex', margin: 0, padding: 0, borderWidth: 0,fontFamily: 'verdana',
    fontSize:'0.8vw',outline:'none',backgroundColor:'#FFFFFF',
}

const img_row1_C1_L2 = {
    width:'90%',height:'auto', cursor:'pointer'

};
const text_Style={
    marginLeft:'0.5vw',fontSize:'0.8vw', justifyContent:'center',alignItems:'center',fontFamily: 'verdana',
};

export default connect()(TextLayer);
